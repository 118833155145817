import { PATH_DASHBOARD } from "../../../routes/paths";
import SvgIconStyle from "../../../components/SvgIconStyle";
import { Iconify } from "../../../pages/components";

const getIcon = (name: any) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const getMuiIconify = (name: any) => (
  <Iconify icon={name} width={24} height={24} />
);
const ICONS = {
  banking: getIcon("ic_banking"),
  packages: getMuiIconify("mdi:package-variant"),
};

const NavConfigNew = [
  {
    id: 1,
    items: [
      {
        title: "Packages",
        titleAr: "الباقات",
        path: PATH_DASHBOARD.company.packages,
        icon: ICONS.packages,
      },
    ],
  },
];

export default NavConfigNew;
