import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import {
  getDocUploads,
  createDocUpload,
  updateDocUpload,
  updateDocUploadStatus,
  deleteDocUpload,
} from "../graphql/all";
import getArchiveDocUploads from "../graphql/all/getArchiveDocUploads";
import getReqApprovalDocs from "../graphql/all/getReqApprovalDocs";
import createGeneralDoc from "../graphql/account/createGeneralDoc";
import inprogressDocUpload from "../graphql/account/inprogressDocUpload";
import useAuth from "./useAuth";
import { statusIds } from "../constants/operation";

export default ({ start = null, end = null }: any) => {
  const { user }: any = useAuth();
  const [getDocs, docsData]: any = useLazyQuery(getDocUploads, {
    variables: { start, end },
    // fetchPolicy: "cache-and-network",
  });
  const [getArchDocs, archdocsData]: any = useLazyQuery(getArchiveDocUploads, {
    variables: { start, end },
    // fetchPolicy: "cache-and-network",
  });
  const [getApprovalDocs, approvaldocsData]: any = useLazyQuery(
    getReqApprovalDocs,
    {
      variables: { start, end },
      // fetchPolicy: "cache-and-network",
    }
  );

  const refetchQrs = {
    refetchQueries: [
      { query: getDocUploads, variables: { start, end } },
      { query: getArchiveDocUploads, variables: { start, end } },
      { query: getReqApprovalDocs, variables: { start, end } },
    ],
  };

  const [addDocUpload] = useMutation(createDocUpload, refetchQrs);
  const [editDocUpload] = useMutation(updateDocUpload, refetchQrs);
  const [editStatusDocUpload] = useMutation(updateDocUploadStatus, refetchQrs);
  const [removeDocUpload] = useMutation(deleteDocUpload, refetchQrs);

  const [addFinance] = useMutation(createGeneralDoc, refetchQrs);
  const [inprogressAdd] = useMutation(inprogressDocUpload, refetchQrs);

  useEffect(() => {
    getDocs();
    getArchDocs();
    getApprovalDocs();
  }, [getDocs]);

  const documents = docsData?.data?.["getDocUploads"]?.data || [];
  const archdocuments =
    archdocsData?.data?.["getArchiveDocUploads"]?.data || [];
  const apprdocuments =
    approvaldocsData?.data?.["getReqApprovalDocs"]?.data || [];
  const waitingcount = apprdocuments?.length || 0;
  const refreshdocs = () => docsData?.refetch();
  const refresharch = () => archdocsData?.refetch();
  const refreshappr = () => approvaldocsData?.refetch();
  const documentsreview = documents?.filter((item: any) =>
    user?.isBranchAdmin
      ? item?.stepId === statusIds.requireAction_7
      : item?.stepId === statusIds.approvelRequireAction_11
  );
  const reviewcount = documentsreview?.length;
  const documentsprocessing = documents?.filter(
    (item: any) =>
      item?.stepId === statusIds.newUploads_1 ||
      item?.stepId === statusIds.inProgress_6 ||
      item?.stepId === statusIds.actionDone_8
  );
  const processingcount = documentsprocessing?.length;
  const alldocuments = [...apprdocuments, ...documents];
  return {
    alldocuments,
    documents,
    archdocuments,
    addDocUpload,
    editDocUpload,
    editStatusDocUpload,
    removeDocUpload,
    refreshdocs,
    refresharch,
    refreshappr,
    loading: docsData?.loading,
    loadingarch: archdocsData?.loading,
    apprdocuments,
    waitingcount,
    documentsreview,
    reviewcount,
    documentsprocessing,
    processingcount,
    addFinance,
    inprogressAdd,
    updateUploadStatus: editStatusDocUpload,
  };
};
