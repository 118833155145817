import { useState } from "react";
import LightboxModal from "./LightboxModal";
import { Box, Stack } from "@mui/material";

export default function LightboxImages({ images, width, height }: any) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const imagesLightbox: any = images?.map((img: any) => img);
  const handleOpenLightbox = (url: any) => {
    const selectedImage = imagesLightbox.findIndex(
      (index: any) => index === url
    );
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  if (!images) return <div></div>;

  return (
    <Stack direction={"row"} spacing={5} sx={{ flexWrap: "wrap" }}>
      {images?.map((img: any, i: any) => (
        <Box
          key={i}
          sx={{
            display: "flex",
            m: 2,
            position: "relative",
            width: width,
            height: height,
            pb: 5,
          }}
        >
          <img
            src={img}
            alt={`Image ${i}`}
            style={{
              width: width,
              height: height,
              objectFit: "cover",
              cursor: "pointer",
            }}
            onClick={() => handleOpenLightbox(images[i])}
          />
        </Box>
      ))}

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </Stack>
  );
}
