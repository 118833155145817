import { format, getTime, formatDistanceToNow } from "date-fns";
import { docTypesExtend, docTypesFull } from "../constants";

// ----------------------------------------------------------------------

export function fDate(date: any) {
  return format(new Date(date), "dd/MM/yyyy");
}
export function fDateMonth(date: any) {
  return format(new Date(date), "MMM yyyy");
}

export function fDateTime(date: any) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}
export function fDateName(date: any) {
  if (!date) return "";
  return format(new Date(date), "dd MMM yyyy");
}

export function fTimestamp(date: any) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: any) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date: any) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export const opTypeFormater = (type: any, isRTL: any) => {
  const op = docTypesExtend?.filter((doc: any) => doc.value === type)?.[0];
  return isRTL ? op?.nameAr : op?.name;
};
export const opTypeFullFormater = (type: any, isRTL: any) => {
  const op = docTypesFull?.filter((doc: any) => doc.value === type)?.[0];
  return isRTL ? op?.nameAr : op?.name;
};
export const moneyFormat = (amount: number) => {
  if (amount) {
    if (!isNaN(amount)) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  }
  return "0.00";
};
export const moneyFormatEmpty = (amount: number) => {
  if (amount) {
    if (!isNaN(amount)) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  }
  return "";
};

export const getDateFormat = (time: any, isRTL: any) => {
  if (time) {
    const date = new Date(time);
    return date.toLocaleString(isRTL ? "ar-QA" : "en-GB", {
      day: "numeric",
      month: "long",
    });
  } else {
    return "";
  }
};
export const getDateName = (time: any) => {
  if (time) {
    const date = new Date(time);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
};

export const getDateMonthFormat = (time: any) => {
  if (time) {
    const date = new Date(time);
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${month}-${year}`;
  } else {
    return "";
  }
};

export function getCurrentMonthDays(month: any) {
  const currentDate = new Date();
  if (month || month === 0) currentDate.setMonth(month);
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const monthDays = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const formattedDate = `${day}-${currentMonth + 1}-${currentYear}`;
    monthDays.push(formattedDate);
  }

  return monthDays;
}

export function getCurrentYearMonths() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const months = [];

  for (let month = 0; month < 12; month++) {
    const formattedDate = `${month + 1}-${currentYear}`;
    months.push(formattedDate);
  }

  return months;
}

export const getLastDays = (days: any) => {
  const list: any = [];

  const time = new Date();
  time.setDate(time.getDate() - days);

  for (let i = 0; i < days; i++) {
    time.setDate(time.getDate() + 1);
    list.push(getDateName(time));
  }
  return list;
};
export const getLastMonths = (num: any) => {
  const list: any = [];

  const time = new Date();
  time.setMonth(time.getMonth() - num);

  for (let i = 0; i < num; i++) {
    time.setMonth(time.getMonth() + 1);
    list.push(getDateMonthFormat(time));
  }
  return list;
};
