import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { getCompany, updateCompany } from "../graphql/all";

export default () => {
  const [getComp, compData]: any = useLazyQuery(getCompany);

  const [editCompany] = useMutation(updateCompany, {
    refetchQueries: [{ query: getCompany }],
  });

  useEffect(() => {
    getComp();
  }, [getComp]);

  let company: any;
  const comp1 = compData?.data?.["getCompany"]?.data || null;
  if (comp1) {
    company = JSON.parse(comp1);
  }

  return { company, editCompany };
};
