import { gql } from "@apollo/client";

export default gql`
  query getNotifications {
    getNotifications {
      ok
      error
      data {
        _id
        branch
        userId
        title
        body
        url
        reminderId
        eventId
        projectId
        contractId
        customerId
        departmentId
        employeeId
        resourseId
        read
        createdAt
        updatedAt
      }
    }
  }
`;
