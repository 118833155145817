import { gql } from "@apollo/client";

export default gql`
  mutation createPosInvoice(
    $time: Date
    $title: String
    $desc: String
    $customerId: String
    $customerName: String
    $customerNameAr: String
    $invoiceitems: String
    $total: Float
    $discount: Float
    $amount: Float
    $amountPaid: Float # cash
    $amountPaid2: Float # card/bank
    $attachments: String
    $stockId: String
    $stockName: String
    $stockNameAr: String
  ) {
    createPosInvoice(
      time: $time
      title: $title
      desc: $desc
      customerId: $customerId
      customerName: $customerName
      customerNameAr: $customerNameAr
      invoiceitems: $invoiceitems
      total: $total
      discount: $discount
      amount: $amount
      amountPaid: $amountPaid
      amountPaid2: $amountPaid2
      attachments: $attachments
      stockId: $stockId
      stockName: $stockName
      stockNameAr: $stockNameAr
    ) {
      ok
      message
      data
      error
    }
  }
`;
