import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useMemo } from "react";
import {
  getItems,
  createItem,
  deleteItem,
  updateItem,
  createMultiItems,
} from "../graphql/item";
import { itemTypes } from "../constants";
// import useAuth from "./useAuth";

export default () => {
  // const { user }: any = useAuth();
  const [getemparts, empData]: any = useLazyQuery(getItems, {
    fetchPolicy: "cache-and-network",
  });

  const [addItem] = useMutation(createItem, {
    refetchQueries: [{ query: getItems }],
  });
  const [editItem] = useMutation(updateItem, {
    refetchQueries: [{ query: getItems }],
  });
  const [removeItem] = useMutation(deleteItem, {
    refetchQueries: [{ query: getItems }],
  });

  const [addMultiItems, { loading }] = useMutation(createMultiItems, {
    refetchQueries: [{ query: getItems }],
  });

  useEffect(() => {
    getemparts();
  }, [getemparts]);

  // const items = useMemo(
  //   () => empData?.data?.["getItems"]?.data || [],
  //   [empData]
  // );

  const items = useMemo(() => {
    const data = empData?.data?.["getItems"]?.data;
    // if (!data) return [];

    // return data
    //   .map((item: any) => {
    //     if (item.itemType === itemTypes.service) {
    //       return item;
    //     } else if (item.itemType === itemTypes.product) {
    //       const userStock = item.stocks.find(
    //         (stock: any) => stock.stockId === user.stockId && stock?.qty > 0
    //       );
    //       if (userStock) {
    //         return {
    //           ...item,
    //           qty: userStock.qty,
    //           cost: userStock.cost,
    //         };
    //       }
    //     }
    //     return null; // Filter out items that don't meet the criteria.
    //   })
    //   .filter((item: any) => item !== null); // Remove null entries.

    return data;
  }, [empData]);

  const services = useMemo(
    () => items?.filter((item: any) => item.itemType === itemTypes.service),
    [items]
  );

  const products = useMemo(
    () => items?.filter((item: any) => item.itemType === itemTypes.product),
    [items]
  );

  const stockitems = useMemo(
    () => products?.filter((item: any) => item.qty > 0),
    [products]
  );

  const refreshitem = () => empData?.refetch();

  return {
    items: items || [],
    stockitems: stockitems || [],
    services: services || [],
    products: products || [],
    refreshitem,
    addItem,
    addMultiItems,
    editItem,
    removeItem,
    loading,
  };
};
