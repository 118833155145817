// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  company: {
    home: path(ROOTS_DASHBOARD, "/company"),
    packages: path(ROOTS_DASHBOARD, "/packages"),
    upgrade: path(ROOTS_DASHBOARD, "/upgrade"),
  },
  checkout: {
    checkoutnewcomp: path(ROOTS_DASHBOARD, "/checkoutnewcomp"),
    checkoutupgrade: path(ROOTS_DASHBOARD, "/checkoutupgrade"),
    checkoutinvoice: path(ROOTS_DASHBOARD, "/checkoutinvoice"),
  },
  upload: {
    uploaddocs: path(ROOTS_DASHBOARD, "/uploaddocs"), // upload page
    documents: path(ROOTS_DASHBOARD, "/documents"), // all document without archive
    waiting: path(ROOTS_DASHBOARD, "/waiting"), // for approval - to be repalced with approval

    approval: path(ROOTS_DASHBOARD, "/approval"), // created by employee - waiting for approval from manager
    review: path(ROOTS_DASHBOARD, "/review"), // action require
    processing: path(ROOTS_DASHBOARD, "/processing"), // created by employee - waiting for approval from manager
    archive: path(ROOTS_DASHBOARD, "/archive"),
  },
  docs: {
    docsreport: path(ROOTS_DASHBOARD, "/docsreport"),
  },
  general: {
    main: path(ROOTS_DASHBOARD, "/main"),
  },
  reports: {
    home: path(ROOTS_DASHBOARD, "/financereport"),
    financereport: path(ROOTS_DASHBOARD, "/financereport"),
    incomereport: path(ROOTS_DASHBOARD, "/incomereport"),
    expensesreport: path(ROOTS_DASHBOARD, "/expensesreport"),
    purchasereport: path(ROOTS_DASHBOARD, "/purchasereport"),
    customersreport: path(ROOTS_DASHBOARD, "/customersreport"),
    customersraseeds: path(ROOTS_DASHBOARD, "/customersraseeds"),
    suppliersreport: path(ROOTS_DASHBOARD, "/suppliersreport"),
    suppliersraseeds: path(ROOTS_DASHBOARD, "/suppliersraseeds"),
    profitabilityreport: path(ROOTS_DASHBOARD, "/profitabilityreport"),
    budgetreport: path(ROOTS_DASHBOARD, "/budgetreport"),
  },
  waiting: {
    view: (id: any) => path(ROOTS_DASHBOARD, `/waiting/${id}`),
    edit: (id: any) => path(ROOTS_DASHBOARD, `/waiting/${id}/edit`),
  },
  documents: {
    view: (id: any) => path(ROOTS_DASHBOARD, `/documents/${id}`),
    edit: (id: any) => path(ROOTS_DASHBOARD, `/documents/${id}/edit`),
  },
  approval: {
    view: (id: any) => path(ROOTS_DASHBOARD, `/approval/${id}`),
    edit: (id: any) => path(ROOTS_DASHBOARD, `/approval/${id}/edit`),
  },
  review: {
    view: (id: any) => path(ROOTS_DASHBOARD, `/review/${id}`),
    edit: (id: any) => path(ROOTS_DASHBOARD, `/review/${id}/edit`),
  },
  processing: {
    view: (id: any) => path(ROOTS_DASHBOARD, `/processing/${id}`),
    edit: (id: any) => path(ROOTS_DASHBOARD, `/processing/${id}/edit`),
  },
  archive: {
    view: (id: any) => path(ROOTS_DASHBOARD, `/archive/${id}`),
    edit: (id: any) => path(ROOTS_DASHBOARD, `/archive/${id}/edit`),
  },
  docsreport: {
    view: (id: any) => path(ROOTS_DASHBOARD, `/docsreport/${id}`),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  user: {
    verify: path(ROOTS_DASHBOARD, "/user/verify"),
    users: path(ROOTS_DASHBOARD, "/user/users"),
    root: path(ROOTS_DASHBOARD, "/user/users"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    edit: (name: any) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    view: (id: any) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
  },
  accountant: {
    main: path(ROOTS_DASHBOARD, "/accountant/main"),
    processing: path(ROOTS_DASHBOARD, "/accountant/processing"),
    review: path(ROOTS_DASHBOARD, "/accountant/review"),
    archive: path(ROOTS_DASHBOARD, "/accountant/archive"),
    documents: path(ROOTS_DASHBOARD, "/accountant/documents"),
  },
  accprocessing: {
    view: (id: any) => path(ROOTS_DASHBOARD, `/accountant/processing/${id}`),
    edit: (id: any) =>
      path(ROOTS_DASHBOARD, `/accountant/processing/${id}/edit`),
  },
  pos: {
    root: path(ROOTS_DASHBOARD, `/pos/root`),
    bills: path(ROOTS_DASHBOARD, `/pos/bills`),
    dailysales: path(ROOTS_DASHBOARD, `/pos/dailysales`),
    posreport: path(ROOTS_DASHBOARD, `/pos/posreport`),
    bill: {
      view: (id: any) => path(ROOTS_DASHBOARD, `/pos/bill/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/pos/bill/${id}/edit`),
    },
  },
  items: {
    category: {
      root: path(ROOTS_DASHBOARD, "/items/category/categories"),
      categories: path(ROOTS_DASHBOARD, "/items/category/categories"),
      new: path(ROOTS_DASHBOARD, "/items/category/new"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/items/category/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/items/category/${id}/edit`),
    },
    product: {
      root: path(ROOTS_DASHBOARD, "/items/product/products"),
      products: path(ROOTS_DASHBOARD, "/items/product/products"),
      new: path(ROOTS_DASHBOARD, "/items/product/new"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/items/product/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/items/product/${id}/edit`),
    },
    service: {
      root: path(ROOTS_DASHBOARD, "/items/service/services"),
      services: path(ROOTS_DASHBOARD, "/items/service/services"),
      new: path(ROOTS_DASHBOARD, "/items/service/new"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/items/service/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/items/service/${id}/edit`),
    },
  },
  sales: {
    customer: {
      root: path(ROOTS_DASHBOARD, "/sales/customer/customers"),
      customers: path(ROOTS_DASHBOARD, "/sales/customer/customers"),
      new: path(ROOTS_DASHBOARD, "/sales/customer/new"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/sales/customer/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/sales/customer/${id}/edit`),
    },
    invoice: {
      root: path(ROOTS_DASHBOARD, "/sales/invoice/invoices"),
      invoices: path(ROOTS_DASHBOARD, "/sales/invoice/invoices"),
      new: path(ROOTS_DASHBOARD, "/sales/invoice/new"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/sales/invoice/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/sales/invoice/${id}/edit`),
    },
  },
  purchase: {
    supplier: {
      root: path(ROOTS_DASHBOARD, "/purchase/supplier/suppliers"),
      suppliers: path(ROOTS_DASHBOARD, "/purchase/supplier/suppliers"),
      new: path(ROOTS_DASHBOARD, "/purchase/supplier/new"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/purchase/supplier/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/purchase/supplier/${id}/edit`),
    },
    purchaseinvoice: {
      root: path(ROOTS_DASHBOARD, "/purchase/purchaseinvoice/purchaseinvoices"),
      purchaseinvoices: path(
        ROOTS_DASHBOARD,
        "/purchase/purchaseinvoice/purchaseinvoices"
      ),
      new: path(ROOTS_DASHBOARD, "/purchase/purchaseinvoice/new"),
      view: (id: any) =>
        path(ROOTS_DASHBOARD, `/purchase/purchaseinvoice/${id}`),
      edit: (id: any) =>
        path(ROOTS_DASHBOARD, `/purchase/purchaseinvoice/${id}/edit`),
    },
  },
  stock: {
    item: {
      root: path(ROOTS_DASHBOARD, "/stock/item/items"),
      items: path(ROOTS_DASHBOARD, "/stock/item/items"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/stock/item/${id}`),
      itemreport: path(ROOTS_DASHBOARD, "/stock/item/itemreport"),
    },
    deliveryin: {
      root: path(ROOTS_DASHBOARD, "/stock/deliveryin/deliveryins"),
      deliveryins: path(ROOTS_DASHBOARD, "/stock/deliveryin/deliveryins"),
      new: path(ROOTS_DASHBOARD, "/stock/deliveryin/new"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/stock/deliveryin/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/stock/deliveryin/${id}/edit`),
    },
    deliveryout: {
      root: path(ROOTS_DASHBOARD, "/stock/deliveryout/deliveryouts"),
      deliveryouts: path(ROOTS_DASHBOARD, "/stock/deliveryout/deliveryouts"),
      new: path(ROOTS_DASHBOARD, "/stock/deliveryout/new"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/stock/deliveryout/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/stock/deliveryout/${id}/edit`),
    },
    stock: {
      root: path(ROOTS_DASHBOARD, "/stock/stock/stocks"),
      stocks: path(ROOTS_DASHBOARD, "/stock/stock/stocks"),
      new: path(ROOTS_DASHBOARD, "/stock/stock/new"),
      view: (id: any) => path(ROOTS_DASHBOARD, `/stock/stock/${id}`),
      edit: (id: any) => path(ROOTS_DASHBOARD, `/stock/stock/${id}/edit`),
    },
  },
};

export const PATH_DOCS = "";
