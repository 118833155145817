import { Box, Card, styled } from "@mui/material";
import Iconify from "../../components/Iconify";
import { useDropzone } from "react-dropzone";
import { getUploadUrl } from "../../components/upload/helpers";
import { useState } from "react";
import ProgressCircular from "./ProgressCircular";

const DropZoneStyle = styled("div")(() => ({
  outline: "none",
  borderRadius: 20,
  "&:hover": { opacity: 0.8, cursor: "pointer" },
  display: "flex",
  flexGrow: 1,
}));

const UploadPhoto = ({ value, onChange }: any) => {
  const [loading, setloading] = useState(false);
  const onDrop = async (newImage: any) => {
    if (newImage) {
      setloading(true);
      const avatar = await getUploadUrl(newImage?.[0]);
      onChange(avatar);
      setloading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: { "image/*": [], "application/pdf": [] },
    });

  const removeImage = () => {
    onChange(null);
  };

  return (
    <>
      {value ? (
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            flexGrow: 1,
          }}
        >
          <img src={value} alt="Uploaded" />
          <Iconify
            icon={"ic:round-close"}
            sx={{
              position: "absolute",
              top: 7,
              right: 7,
              width: 20,
              height: 20,
              color: "#fff",
              zIndex: 10,
              cursor: "pointer",
              backgroundColor: "#00000022",
            }}
            onClick={removeImage}
          />
        </Card>
      ) : (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.8 }),
            ...(isDragReject && {
              color: "error.main",
              borderColor: "error.light",
              bgcolor: "error.lighter",
            }),
          }}
        >
          <input {...getInputProps()} />
          {loading ? (
            <Card
              sx={{
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <ProgressCircular></ProgressCircular>
            </Card>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                flexGrow: 1,
              }}
            >
              <Iconify
                icon={"ic:round-add-a-photo"}
                sx={{
                  color: "#eee",
                  m: 5,
                  width: 60,
                  height: 60,
                }}
              />
            </Box>
          )}
        </DropZoneStyle>
      )}
    </>
  );
};

export default UploadPhoto;
