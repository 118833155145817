import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Stack, Drawer } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import cssStyles from "../../../utils/cssStyles";
import { NAVBAR } from "../../../config";
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
import navConfig from "./NavConfig";
import NavConfigNew from "./NavConfigNew";
import CollapseButton from "./CollapseButton";
import useAuth from "../../../hooks/useAuth";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useDocUpload from "../../../hooks/useDocUpload";
import LogoName from "../../../components/LogoName";
import { useSubscription } from "@apollo/client";
import approveAdded from "../../../graphql/all/approveAdded";
import statusUpdated from "../../../graphql/all/statusUpdated";
import NavConfigAdmin from "./NavConfigAdmin";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: any) {
  const theme = useTheme();
  const { hasCompany, user, view }: any = useAuth();
  const { pathname } = useLocation();
  const isRTL = theme.direction === "rtl";
  const isDesktop = useResponsive("up", "lg", null, null);
  const { height } = useWindowDimensions();
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverLeave,
  } = useCollapseDrawer();
  const {
    waitingcount,
    reviewcount,
    processingcount,
    refreshappr,
    refreshdocs,
  } = useDocUpload({
    start: null,
    end: null,
  });

  const isAccountant = user?.isAccountant;
  const mainNav =
    isAccountant && view === "isAccountant" ? NavConfigAdmin : navConfig;

  const approvesub = useSubscription(approveAdded, {
    fetchPolicy: "network-only",
    shouldResubscribe: true,
  });
  const statussub = useSubscription(statusUpdated, {
    fetchPolicy: "network-only",
    shouldResubscribe: true,
  });
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    if (
      approvesub?.data?.approveAdded?.branch &&
      approvesub?.data?.approveAdded?.branch === user?.branch
    ) {
      refreshappr();
    }
  }, [approvesub]);
  useEffect(() => {
    if (
      statussub?.data?.statusUpdated?.branch &&
      statussub?.data?.statusUpdated?.branch === user?.branch
    ) {
      refreshdocs();
    }
  }, [statussub]);

  const renderContent = (
    <Scrollbar
      sx={{
        height,
        "& .simplebar-content": {
          minHeight: isDesktop ? height - 64 : undefined,
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      <NavSectionVertical
        isRTL={isRTL}
        navConfig={hasCompany ? mainNav : NavConfigNew}
        isCollapse={isCollapse}
        waitingcount={waitingcount}
        reviewcount={reviewcount}
        processingcount={processingcount}
        isDesktop={isDesktop}
      />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: theme.palette.primary.main,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          // onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur({}),
                boxShadow: (theme: any) => theme.customShadows.z24,
              }),
            },
          }}
        >
          <Stack
            spacing={3}
            sx={{
              py: 1.5,
              px: 2,
              flexShrink: 0,
              ...(isCollapse && { alignItems: "center" }),
              // backgroundColor: theme.palette.primary.main,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              {!isCollapse && <LogoName />}
              {isDesktop && (
                <CollapseButton
                  onToggleCollapse={onToggleCollapse}
                  collapseClick={collapseClick}
                  isRTL={isRTL}
                />
              )}
            </Stack>
          </Stack>
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
