import useAuth from "./useAuth";

export default () => {
  const { user, template }: any = useAuth();
  const {
    isBranchsAdmin,
    isBranchAdmin,
    isAccountant,
    isAdmin,
    isEditor,
    isWriter,
    isViewer,
  } = user;
  const tempoptions = template?.options;
  const frontpageoptions = template?.frontpageoptions;
  const uploadoptions = template?.uploadoptions;
  return {
    isBranchsAdmin,
    isBranchAdmin: isBranchsAdmin || isBranchAdmin || isAccountant,
    isAdmin: isBranchsAdmin || isBranchAdmin || isAccountant || isAdmin,
    isEditor:
      isBranchsAdmin || isBranchAdmin || isAccountant || isAdmin || isEditor,
    isWriter:
      isBranchsAdmin ||
      isBranchAdmin ||
      isAccountant ||
      isAdmin ||
      isEditor ||
      isWriter,
    isViewer:
      isBranchsAdmin ||
      isBranchAdmin ||
      isAccountant ||
      isAdmin ||
      isEditor ||
      isWriter ||
      isViewer,
    tempoptions,
    frontpageoptions,
    uploadoptions,
  };
};
