import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, Button, Typography } from "@mui/material";
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
import cssStyles from "../../../utils/cssStyles";
import { HEADER, NAVBAR } from "../../../config";
import Logo from "../../../components/Logo";
import Iconify from "../../../components/Iconify";
import { IconButtonAnimate } from "../../../components/animate";

import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLocales from "../../../hooks/useLocales";
import useAuth from "../../../hooks/useAuth";
import { useSubscription } from "@apollo/client";
import CompanyPopover from "./CompanyPopover";
import { LoadingButton } from "@mui/lab";
import notifiAdded from "../../../graphql/all/notifiAdded";
import Image from "../../../components/Image";

// ----------------------------------------------------------------------

const RootStyle: any = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }: any) => ({
  ...cssStyles(theme).bgBlur({}),
  boxShadow: "8px",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),

  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  isAccountant,
  company,
}: any) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg", null, null);
  const isTablet = useResponsive("up", "md", null, null);
  const isMobile = !isDesktop && !isTablet;
  const { pathname } = useLocation();
  const { translate, isRTL } = useLocales();
  const { user, hasCompany, notify, updatenotify }: any = useAuth();

  const notifisub = useSubscription(notifiAdded, {
    fetchPolicy: "network-only",
    shouldResubscribe: true,
  });

  const refreshchartData = () => {
    window.location.reload();
  };

  const isPOS = pathname.includes("/dashboard/pos");

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
          backgroundColor: "background.paper",
          flexDirection: isRTL && !isDesktop ? "row-reverse" : "row",
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            color="primary"
            sx={{
              color: "text.primary",
            }}
          >
            <Iconify color="#5D3681" icon="eva:menu-fill" />
          </IconButtonAnimate>
        )}

        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: isRTL ? "row-reverse" : "row",
            mx: 2,
          }}
        >
          {!isAccountant && !isDesktop && (
            <Image
              disabledEffect
              visibleByDefault
              alt={"Tadween"}
              src="https://res.cloudinary.com/tadween/image/upload/v1697228139/logo3_orcvr4.png"
              sx={{ maxWidth: 100, ml: 5 }}
            />
          )}
          {hasCompany &&
            pathname !== "/dashboard/uploaddocs" &&
            !isAccountant &&
            !isMobile &&
            !isPOS && (
              <Button
                variant="contained"
                component={Link}
                to={PATH_DASHBOARD.upload.uploaddocs}
                startIcon={
                  <Iconify
                    style={{ fontSize: isDesktop ? 24 : 20 }}
                    icon={"eva:cloud-upload-outline"}
                  />
                }
                sx={{
                  height: isDesktop ? 40 : 35,
                  minWidth: isDesktop ? 150 : 120,
                }}
              >
                <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
                  {translate("upload")}
                </Typography>
              </Button>
            )}
          {isAccountant && (
            <Typography
              sx={{
                color: "#333",
                fontWeight: "bold",
                textAlign: isRTL && !isDesktop ? "right" : "left",
              }}
            >
              {company}
            </Typography>
          )}
        </Box>

        <Stack
          direction={isRTL && !isDesktop ? "row-reverse" : "row"}
          alignItems="center"
          spacing={{ xs: 1.5, sm: 1.5 }}
        >
          {isDesktop && (
            <>
              {!isAccountant && <CompanyPopover />}
              {pathname === "/dashboard/main" && (
                <LoadingButton
                  style={{
                    padding: 0,
                    minHeight: 45,
                    minWidth: 45,
                    borderRadius: 22.5,
                  }}
                  onClick={refreshchartData}
                >
                  <Iconify icon={"eva:refresh-fill"} width={24} height={24} />
                </LoadingButton>
              )}
            </>
          )}
          {isDesktop && <LanguagePopover />}
          {!isDesktop && pathname === "/dashboard/main" && (
            <LoadingButton
              style={{
                marginLeft: isRTL ? -10 : undefined,
                marginRight: isRTL ? undefined : -10,
                minHeight: 40,
                minWidth: 40,
                borderRadius: 22.5,
              }}
              onClick={refreshchartData}
            >
              <Iconify icon={"eva:refresh-fill"} width={24} height={24} />
            </LoadingButton>
          )}
          <NotificationsPopover
            user={user}
            updatenotify={updatenotify}
            notify={notify}
            isRTL={isRTL}
            notifisub={notifisub}
            isDesktop={isDesktop}
          />
          <AccountPopover isAccountant={isAccountant} isRTL={isRTL} />
        </Stack>
      </Toolbar>
      {/* {!isDesktop && pathname !== "/dashboard/main" && !isAccountant && (
        <Box
          sx={{
            position: "absolute",
            top: 60,
            right: isRTL ? 0 : undefined,
            left: isRTL ? undefined : 0,
            zIndex: 115,
          }}
        >
          <IconButton onClick={goBack}>
            <Iconify icon={"eva:arrow-ios-back-fill"} width={32} height={32} />
          </IconButton>
        </Box>
      )} */}

      {/* {(user?.isBranchsAdmin || user?.isAccountant) && (
        <AlertCode
          isRTL={isRTL}
          verified={verified}
          isDesktop={isDesktop}
          handleSendCode={handleSendCode}
        ></AlertCode>
      )} */}
    </RootStyle>
  );
}
