import { InputAdornment, TextField, Tooltip, Grid } from "@mui/material";
import Iconify from "../../components/Iconify";
import { LoadingButton } from "@mui/lab";
import { downloadCSV } from "../../utils/helpers";

export default function SearchToolbar({
  filterName,
  onFilterName,
  name = "documents",
  isRTL,
  rows,
  refresh,
  loading,
  columns,
}: any) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={1} md={1}></Grid>
      <Grid item xs={10} md={10}>
        <TextField
          fullWidth
          value={filterName}
          size="small"
          onChange={(event) => onFilterName(event.target.value)}
          placeholder={isRTL ? "البحث ..." : "Search ..."}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={1} md={1}></Grid>

      {/* <Grid item xs={3} md={1}>
        {refresh && (
          <Tooltip title={isRTL ? "تحديث" : "Refresh"}>
            <LoadingButton
              style={{
                padding: 0,
                minHeight: 40,
                minWidth: 40,
                borderRadius: 20,
                marginLeft: 10,
                marginRight: 10,
              }}
              onClick={refresh}
              loading={loading}
            >
              <Iconify icon={"mdi:refresh"} width={26} height={26} />
            </LoadingButton>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={3} md={1}>
        {rows && (
          <Tooltip title={isRTL ? "تحميل" : "Download"}>
            <LoadingButton
              style={{
                padding: 0,
                minHeight: 40,
                minWidth: 40,
                borderRadius: 20,
                marginLeft: 5,
                marginRight: 5,
              }}
              onClick={() => downloadCSV({ name, rows, columns })}
            >
              <Iconify icon={"mdi:download"} width={26} height={26} />
            </LoadingButton>
          </Tooltip>
        )}
      </Grid> */}
    </Grid>
  );
}
