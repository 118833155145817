import { gql } from "@apollo/client";

export default gql`
  mutation createCategory(
    $catType: Int
    $disabled: Boolean
    $additionId: String
    $name: String
    $nameAr: String
    $desc: String
    $avatar: String
    $color: String
    $order: Int
  ) {
    createCategory(
      catType: $catType
      disabled: $disabled
      additionId: $additionId
      name: $name
      nameAr: $nameAr
      desc: $desc
      avatar: $avatar
      color: $color
      order: $order
    ) {
      ok
      message
      data
      error
    }
  }
`;
