import { createContext, useContext, useReducer } from "react";
import { getStoreItem, setStoreItem } from "../redux/helpers";
import { toFixed2 } from "../utils/helpers";
import { itemTypes } from "../constants";

const initstate = {
  bill: {
    time: null,
    user: null,
    customer: null,
    invoiceitems: [],
    total: 0,
    totalCost: 0,
    totalProdustsCost: 0,
    discount: 0,
    tax: 0,
    taxpercent: 0,
    grandTotal: 0,
    cash: 0,
    card: 0,
    paytype: 1,
    desc: "",
  },

  onBillChange: (bill: any) => {},
  onBillClear: () => {},
  onBillDiscount: (discount: number) => {},
  onBillTax: (tax: number, taxpercent: number) => {},
  onBillCustomer: (customer: any) => {},
  onBillUser: (user: any) => {},
  onBillItemAdd: (item: any) => {},
  onBillItemRemove: (id: string) => {},
  onBillItemSetQty: (id: string, qty: number) => {},
  onBillItemUpdate: (id: string, item: any) => {},
  calculateTotals: () => {},
  onPaymentsChange: ({ cash, card, paytype }: any) => {},
  onBillDesc: (desc: string) => {},
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "onBillChange":
      return { ...state, bill: action.payload.bill };
    case "onBillClear":
      return { ...state, bill: initstate.bill };
    case "onBillDiscount":
      return {
        ...state,
        bill: { ...state.bill, discount: action.payload.discount },
      };
    case "onBillDesc":
      return {
        ...state,
        bill: { ...state.bill, desc: action.payload.desc },
      };
    case "onBillTax":
      return {
        ...state,
        bill: {
          ...state.bill,
          tax: action.payload.tax,
          taxpercent: action.payload.taxpercent,
        },
      };
    case "onBillCustomer":
      return {
        ...state,
        bill: { ...state.bill, customer: action.payload.customer },
      };
    case "onBillUser":
      return { ...state, bill: { ...state.bill, user: action.payload.user } };
    // case "onBillItemAdd":
    //   return {
    //     ...state,
    //     bill: {
    //       ...state.bill,
    //       invoiceitems: [...state.bill.invoiceitems, action.payload.invoiceitems],
    //     },
    //   };
    case "onBillItemAdd":
      const itemExists = state.bill.invoiceitems.some(
        (item: any) => item._id === action.payload.item._id
      );
      if (itemExists) {
        return {
          ...state,
          bill: {
            ...state.bill,
            invoiceitems: state.bill.invoiceitems.map((item: any) =>
              item._id === action.payload.item._id
                ? {
                    ...item,
                    itemId: action.payload.item._id,
                    qty: item.qty + 1,
                    totalPrice: item.price * (item.qty + 1),
                    totalCost: item.cost * (item.qty + 1),
                  }
                : item
            ),
          },
        };
      } else {
        return {
          ...state,
          bill: {
            ...state.bill,
            invoiceitems: [
              ...state.bill.invoiceitems,
              {
                ...action.payload.item,
                itemId: action.payload.item._id,
                qty: 1,
                totalPrice: action.payload.item.price,
                totalCost: action.payload.item.cost,
              },
            ],
          },
        };
      }

    case "onBillItemSetQty":
      return {
        ...state,
        bill: {
          ...state.bill,
          invoiceitems: state.bill.invoiceitems.map((item: any) =>
            item._id === action.payload.id
              ? {
                  ...item,
                  qty: action.payload.qty,
                  totalPrice: action.payload.qty * item.price,
                  totalCost: action.payload.qty * item.cost,
                }
              : item
          ),
        },
      };
    case "onBillItemRemove":
      return {
        ...state,
        bill: {
          ...state.bill,
          invoiceitems: state.bill.invoiceitems.filter(
            (item: any) => item._id !== action.payload.id
          ),
        },
      };
    case "onBillItemUpdate":
      return {
        ...state,
        bill: {
          ...state.bill,
          invoiceitems: state.bill.invoiceitems.map((item: any) =>
            item.id === action.payload._id ? action.payload.item : item
          ),
        },
      };
    case "onPaymentsChange":
      return {
        ...state,
        bill: {
          ...state.bill,
          cash: action.payload.cash,
          card: action.payload.card,
          paytype: action.payload.paytype,
        },
      };

    case "calculateTotals":
      const total = toFixed2(
        state.bill.invoiceitems.reduce(
          (total: any, item: any) => total + item?.price * item?.qty,
          0
        )
      );
      const totalCost = toFixed2(
        state.bill.invoiceitems.reduce(
          (total: any, item: any) => total + (item?.cost || 0) * item?.qty,
          0
        )
      );
      const totalProductsCost = toFixed2(
        state.bill.invoiceitems
          .filter((item: any) => item.itemType === itemTypes.product)
          .reduce((total: number, item: any) => total + item.totalCost, 0)
      );

      const grandTotal = toFixed2(total - state.bill.discount);
      const type = state.bill.paytype;
      const cash =
        type === 1
          ? grandTotal
          : type === 3
          ? toFixed2(grandTotal - state.bill.card)
          : 0;
      const card = type === 2 ? grandTotal : type === 3 ? state.bill.card : 0;

      const newState = {
        ...state,
        bill: {
          ...state.bill,
          total: total,
          grandTotal: grandTotal,
          totalCost,
          totalProductsCost,
          cash,
          card,
        },
      };
      setStoreItem("billState", newState);
      return newState;

    default:
      throw new Error("Unexpected action");
  }
};

const Context = createContext(initstate);

export const POSProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(
    reducer,
    getStoreItem("billState") || initstate
  );
  const onBillChange = (bill: any) =>
    dispatch({ type: "onBillChange", payload: { bill } });
  const onBillClear = () => dispatch({ type: "onBillClear" });
  const onBillDiscount = (discount: number) =>
    dispatch({ type: "onBillDiscount", payload: { discount } });
  const onBillDesc = (desc: number) =>
    dispatch({ type: "onBillDesc", payload: { desc } });
  const onBillTax = (tax: number, taxpercent: number) =>
    dispatch({ type: "onBillTax", payload: { tax, taxpercent } });
  const onBillCustomer = (customer: any) =>
    dispatch({ type: "onBillCustomer", payload: { customer } });
  const onBillUser = (user: any) =>
    dispatch({ type: "onBillUser", payload: { user } });
  const onBillItemAdd = (item: any) =>
    dispatch({ type: "onBillItemAdd", payload: { item } });
  const onBillItemSetQty = (id: string, qty: number) =>
    dispatch({ type: "onBillItemSetQty", payload: { id, qty } });
  const onBillItemRemove = (id: string) =>
    dispatch({ type: "onBillItemRemove", payload: { id } });
  const onBillItemUpdate = (id: string, item: any) =>
    dispatch({ type: "onBillItemUpdate", payload: { id, item } });
  const onPaymentsChange = ({ cash, card, paytype }: any) =>
    dispatch({ type: "onPaymentsChange", payload: { cash, card, paytype } });
  const calculateTotals = () => dispatch({ type: "calculateTotals" });
  return (
    <Context.Provider
      value={{
        ...state,
        onBillChange,
        onBillClear,
        onBillDiscount,
        onBillDesc,
        onBillTax,
        onBillCustomer,
        onBillUser,
        onBillItemAdd,
        onBillItemSetQty,
        onBillItemRemove,
        onBillItemUpdate,
        onPaymentsChange,
        calculateTotals,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const usePOSContext = () => useContext(Context);
