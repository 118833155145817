import { gql } from "@apollo/client";

export default gql`
  query getSuppliers {
    getSuppliers {
      ok
      error
      data {
        _id
        branch
        disabled
        additionId
        name
        nameAr
        phone
        mobile
        address
        email
        avatar
        logo

        amount
        totalinvoiced
        totalDiscount
        totalPurchaseInvoiced
        totalPurchasePaid
        totalPurchaseDiscount
        toatlProdExpenses
        totalpaid
        toatlExpenses
        totalkaidsdebit
        totalKaidscredit

        userId
        createdAt
        updatedAt
      }
    }
  }
`;
