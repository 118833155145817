import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import {
  createStock,
  deleteStock,
  getStocks,
  updateStock,
} from "../graphql/stock";

export default () => {
  const [getCusts, custData]: any = useLazyQuery(getStocks);

  const [addStock] = useMutation(createStock, {
    refetchQueries: [{ query: getStocks }],
  });
  const [editStock] = useMutation(updateStock, {
    refetchQueries: [{ query: getStocks }],
  });
  const [removeStock] = useMutation(deleteStock, {
    refetchQueries: [{ query: getStocks }],
  });

  useEffect(() => {
    getCusts();
  }, [getCusts]);

  const stocks = custData?.data?.["getStocks"]?.data || [];
  const refreshstock = () => custData?.refetch();
  const loading = custData?.loading;
  return {
    stocks,
    refreshstock,
    addStock,
    editStock,
    removeStock,
    loading,
  };
};
