import {
  operationTypes,
  docTypesExtend,
  docTypesFull,
  docTypesStock,
  opTypesSale,
  opTypesPurchase,
  opTypesSalePurchase,
  docStatus,
  getPackages,
  getPack,
  itemTypes,
  catTypesList,
  deliveryInTypes,
  deliveryOutTypes,
} from "./operation";

// export const graphqlURI = "http://jadwal-server/graphql";
// export const wsURI = "ws://jadwal-server/graphql";

export const graphqlURI = "https://admin.tadween.qa/graphql";
export const wsURI = `wss://admin.tadween.qa/graphql`;

export const daystoadd = 7;

export const appversion = "1.0.4";

export const CLOUD_NAME = "tadween";
export const UPLOAD_PRESET = "f8j1tsev";
export const imageUploadOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1800,
  useWebWorker: true,
};
export const thumbUploadOptions = {
  maxSizeMB: 0.1,
  maxWidthOrHeight: 300,
  useWebWorker: true,
};

export {
  operationTypes,
  docTypesExtend,
  docTypesFull,
  docTypesStock,
  opTypesSale,
  opTypesPurchase,
  opTypesSalePurchase,
  docStatus,
  getPackages,
  getPack,
  itemTypes,
  catTypesList,
  deliveryInTypes,
  deliveryOutTypes,
};
