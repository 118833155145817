// ----------------------------------------------------------------------

const PRIMARY_NAME = ["A", "N", "H", "L", "Q", "9", "8"];
const INFO_NAME = ["F", "G", "T", "I", "J", "1", "2", "3"];
const SUCCESS_NAME = ["K", "D", "Y", "B", "O", "4", "5"];
const WARNING_NAME = ["P", "E", "R", "S", "C", "U", "6", "7"];
const ERROR_NAME = ["V", "W", "X", "M", "Z"];

function getFirstCharacter(name: any) {
  return name && name.charAt(0).toUpperCase();
}

function getAvatarColor(name: any) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return "primary";
  if (INFO_NAME.includes(getFirstCharacter(name))) return "info";
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return "success";
  if (WARNING_NAME.includes(getFirstCharacter(name))) return "warning";
  if (ERROR_NAME.includes(getFirstCharacter(name))) return "warning";
  return "default";
}

export default function createAvatar(name: any) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
  };
}
// https://res.cloudinary.com/tadween/image/upload/w_100,h_100,c_fill/v1685219357/qvn1lpbtbgzfaezm1zcx.jpg

function addTransformationToURL(url: any) {
  var parts = url.split("upload/");
  if (parts.length > 1) {
    var transformedURL = parts[0] + "upload/w_100,h_100,c_fill/" + parts[1];
    return transformedURL;
  } else {
    return url;
  }
}

export const getThumbs = (images: any) => {
  if (images.length === 0) return [];
  return images.map((url: any) => addTransformationToURL(url));
};
