import { useEffect, useState } from "react";
import useLocales from "./useLocales";

type Column = {
  id: string;
  align?: string;
  width?: number;
  minwidth?: number;
  maxwidth?: number;
  type?: string;
};

const useColumns = (list: Column[]) => {
  const { translate } = useLocales();
  const [head, setHead] = useState<Column[]>([]);

  useEffect(() => {
    const TABLE_HEAD = list.map((item: any) => {
      return {
        id: item.id,
        label: translate(item?.id),
        name: item.id,
        columnName: item.id,
        title: translate(item?.id),
        align: item.align || "left",
        width: item.width || 150,
        minwidth: item.minwidth || 150,
        maxwidth: item.maxwidth || 250,
        type: item.type || "text",
      };
    });
    setHead(TABLE_HEAD);
  }, []);

  return head;
};

export default useColumns;
