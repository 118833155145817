import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import Iconify from "./Iconify";

interface UploadXlsxProps {
  isRTL: boolean;
  enqueueSnackbar: (message: string, options: any) => void;
  onChange: (data: any) => void;
  xlsxHeader: string[];
  title?: string;
}

const UploadXlsx = ({
  isRTL,
  enqueueSnackbar,
  onChange,
  title,
}: UploadXlsxProps) => {
  const acceptedExtensions = [".xlsx", ".xls"];

  const onDrop = async (newFile: any) => {
    if (newFile) {
      const file = newFile?.[0];
      const ext = file.name.split(".").pop();
      if (!acceptedExtensions.includes(`.${ext}`)) {
        enqueueSnackbar(
          "Invalid file type. Please upload a valid file type : xlsx or xls",
          {
            variant: "error",
          }
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = function (e: any) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        if (parsedData?.length > 0) {
        } else {
          enqueueSnackbar("No data found in the file, or data not valid", {
            variant: "error",
          });
        }
        onChange(parsedData);
      };
      reader.readAsBinaryString(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button
          variant="outlined"
          startIcon={<Iconify icon={"mdi:microsoft-excel"} />}
        >
          {title ? title : isRTL ? "رفع ملف" : "Upload File"}
        </Button>
      </div>
    </>
  );
};

export default UploadXlsx;
