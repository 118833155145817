import { gql } from "@apollo/client";

export default gql`
  mutation updateSupplier(
    $_id: String
    $disabled: Boolean
    $name: String
    $nameAr: String
    $phone: String
    $mobile: String
    $email: String
    $address: String
    $avatar: String
    $logo: String
  ) {
    updateSupplier(
      _id: $_id
      disabled: $disabled
      name: $name
      nameAr: $nameAr
      phone: $phone
      mobile: $mobile
      email: $email
      address: $address
      avatar: $avatar
      logo: $logo
    ) {
      ok
      message
      data
      error
    }
  }
`;
