import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { graphqlURI, wsURI } from "../constants";
import { onError } from "@apollo/client/link/error";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";

const accessToken = localStorage.getItem("accessToken");
const token = JSON.stringify({ accessToken });
const httpLink = createHttpLink({ uri: graphqlURI });
const wsLink = new GraphQLWsLink(createClient({ url: wsURI }));

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: token ? `Tadween ${token}` : "",
    },
  };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  try {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message }) => {
        if (message === "auth token error") {
          console.log("auth token error");
          // dispatch({ type: "logout" });
        }
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  } catch (error) {
    console.log(error);
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(splitLink),
  cache: new InMemoryCache(),
});
