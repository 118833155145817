// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name: any) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const getEvaIcon = (name: any) => (
  <Iconify icon={name} width={24} height={24} />
);
const getMuiIconify = (name: any) => (
  <Iconify icon={name} width={24} height={24} />
);

export const ICONS = {
  blog: getIcon("ic_blog"),
  chat: getIcon("ic_chat"),
  kanban: getIcon("ic_kanban"),
  invoice: getIcon("ic_invoice"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  booking: getMuiIconify("mdi:bank"),
  company: getMuiIconify("mdi:company"),
  banking: getMuiIconify("mdi:bank"),
  user: getMuiIconify("mdi:user"),
  cart: getMuiIconify("mdi:cart"),
  calendar: getMuiIconify("mdi:calendar"),
  mail: getMuiIconify("mdi:email"),
  dashboard: getMuiIconify("mdi:home-variant"),
  sent: getMuiIconify("mdi:navigation-variant"),
  processing: getMuiIconify("mdi:monitor-dashboard"),
  archive: getMuiIconify("mdi:archive"),
  documents: getMuiIconify("mdi:file-text"),
  uploads: getMuiIconify("mdi:cloud-upload"),
  reports: getMuiIconify("mdi:report-box"),
  balance: getMuiIconify("mdi:scale-balance"),
  approval: getMuiIconify("mdi:approval"),
  review: getMuiIconify("mdi:print-preview"),
  finance: getMuiIconify("mdi:finance"),
  operation: getMuiIconify("mdi:checkbox-multiple-marked"),
  print: getMuiIconify("mdi:printer"),
  bill: getMuiIconify("mdi:invoice-multiple"),
  car: getMuiIconify("mdi:car"),
  list: getMuiIconify("mdi:format-list-bulleted"),
  stock: getMuiIconify("mdi:clipboard-list"),
  pos: getMuiIconify("mdi:point-of-sale"),
  sales: getMuiIconify("mdi:store"),
  classroom: getMuiIconify("mdi:school"),
  course: getMuiIconify("mdi:book-open-variant"),
  lesson: getMuiIconify("mdi:book-open-page-variant"),
  teachers: getMuiIconify("mdi:account-group"),
  teacher: getMuiIconify("mdi:account"),
  student: getMuiIconify("mdi:account"),
  students: getMuiIconify("mdi:account-group"),
  items: getMuiIconify("mdi:package-variant"),
  packages: getMuiIconify("mdi:package-variant"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: "Documents",
    id: 1,
    items: [
      {
        id: 1,
        title: "Main",
        titleAr: "الرئيسية",
        path: PATH_DASHBOARD.general.main,
        icon: ICONS.dashboard,
      },
    ],
  },
  {
    id: 2,
    subheader: "Uploads",
    subheaderAr: "المرفوعات",
    items: [
      {
        id: 1,
        title: "Approval",
        titleAr: "للموافقة",
        path: PATH_DASHBOARD.upload.approval,
        icon: ICONS.approval,
      },
      {
        id: 2,
        title: "Review",
        titleAr: "للمراجعة",
        path: PATH_DASHBOARD.upload.review,
        icon: ICONS.review,
      },
      {
        id: 3,
        title: "Processing",
        titleAr: "تحت الاجراء",
        path: PATH_DASHBOARD.upload.processing,
        icon: ICONS.processing,
      },
      {
        id: 4,
        title: "All Uploads",
        titleAr: "كل المرفوعات",
        path: PATH_DASHBOARD.upload.documents,
        icon: ICONS.uploads,
      },
    ],
  },
  {
    id: 3.5,
    subheader: "Operations",
    subheaderAr: "العمليات",
    items: [
      {
        id: 0,
        title: "POS",
        titleAr: "نقطة البيع",
        path: PATH_DASHBOARD.pos.root,
        icon: ICONS.pos,
      },
      {
        id: 1,
        title: "Sales",
        titleAr: "المبيعات",
        icon: ICONS.sales,
        children: [
          {
            id: 1,
            title: "Sales Invoices",
            titleAr: "فواتير البيع",
            path: PATH_DASHBOARD.sales.invoice.root,
          },
          {
            id: 2,
            title: "Customers",
            titleAr: "العملاء",
            path: PATH_DASHBOARD.sales.customer.root,
          },
        ],
      },
      {
        id: 2,
        title: "Purchase",
        titleAr: "المشتريات",
        icon: ICONS.cart,
        children: [
          {
            id: 1,
            title: "Purchase Invoices",
            titleAr: "فواتير الشراء",
            path: PATH_DASHBOARD.purchase.purchaseinvoice.root,
          },
          {
            id: 2,
            title: "Suppliers",
            titleAr: "الموردين",
            path: PATH_DASHBOARD.purchase.supplier.root,
          },
        ],
      },
      {
        id: 3,
        title: "Stock",
        titleAr: "إدارة المخزون",
        icon: ICONS.stock,
        children: [
          // {
          //   id: 0,
          //   title: "Stocks",
          //   titleAr: "المخازن",
          //   path: PATH_DASHBOARD.stock.stock.root,
          // },
          {
            id: 1,
            title: "Stock In",
            titleAr: "إدخال بضاعة",
            path: PATH_DASHBOARD.stock.deliveryin.root,
          },
          {
            id: 2,
            title: "Stock Out",
            titleAr: "إخراج بضاعة",
            path: PATH_DASHBOARD.stock.deliveryout.root,
          },
          {
            id: 3,
            title: "Stock List",
            titleAr: "قائمة المخزون",
            path: PATH_DASHBOARD.stock.item.root,
          },

          {
            id: 4,
            title: "Items Report",
            titleAr: "تقرير البنود",
            path: PATH_DASHBOARD.stock.item.itemreport,
          },
          {
            id: 5,
            title: "Products",
            titleAr: "المنتجات",
            path: PATH_DASHBOARD.items.product.root,
          },
          {
            id: 6,
            title: "Categories",
            titleAr: "التصنيفات",
            path: PATH_DASHBOARD.items.category.root,
          },
        ],
      },
      {
        id: 4,
        title: "Services",
        titleAr: "الخدمات",
        path: PATH_DASHBOARD.items.service.root,
        icon: ICONS.items,
      },
    ],
  },
  {
    // subheader: "Documents",
    id: 3,
    subheader: "Archive",
    subheaderAr: "الأرشيف",
    items: [
      {
        id: 1,
        title: "Upload Archive",
        titleAr: "ارشيف المرفوعات",
        path: PATH_DASHBOARD.upload.archive,
        icon: ICONS.archive,
      },
      {
        id: 2,
        title: "Document Archive",
        titleAr: "المستندات المسجلة",
        path: PATH_DASHBOARD.docs.docsreport,
        icon: ICONS.documents,
      },
    ],
  },

  {
    id: 4,
    subheader: "Finance",
    subheaderAr: "الحسابات",
    items: [
      {
        id: 2,
        title: "Reports",
        titleAr: "التقارير",
        // path: PATH_DASHBOARD.reports.home,
        icon: ICONS.reports,
        children: [
          {
            title: "Sales Report",
            titleAr: "تقرير المبيعات",
            path: PATH_DASHBOARD.reports.incomereport,
          },
          {
            title: "Expenses Report",
            titleAr: "تقرير المصروفات",
            path: PATH_DASHBOARD.reports.expensesreport,
          },
          {
            title: "Purchase Report",
            titleAr: "تقرير المشتريات",
            path: PATH_DASHBOARD.reports.purchasereport,
          },
          {
            title: "Finance Report",
            titleAr: "تقرير الحسابات",
            path: PATH_DASHBOARD.reports.financereport,
          },
          {
            title: "Profitability Report",
            titleAr: "تقرير الربحية",
            path: PATH_DASHBOARD.reports.profitabilityreport,
          },
          {
            title: "Final Accounts",
            titleAr: "الحسابات الختامية",
            path: PATH_DASHBOARD.reports.budgetreport,
          },
        ],
      },
      {
        id: 3,
        title: "Statements",
        titleAr: "الكشوف",
        // path: PATH_DASHBOARD.reports.customersreport,
        icon: ICONS.balance,
        children: [
          {
            title: "Customers Report",
            titleAr: "تقرير العملاء",
            path: PATH_DASHBOARD.reports.customersreport,
          },
          {
            title: "Customers Balance",
            titleAr: "ارصدة العملاء",
            path: PATH_DASHBOARD.reports.customersraseeds,
          },
          {
            title: "Suppliers Report",
            titleAr: "تقرير الموردين",
            path: PATH_DASHBOARD.reports.suppliersreport,
          },
          {
            title: "Suppliers Balance",
            titleAr: "ارصدة الموردين",
            path: PATH_DASHBOARD.reports.suppliersraseeds,
          },
        ],
      },
    ],
  },
  {
    // subheader: "Documents",
    id: 5,
    items: [
      {
        title: "Users",
        titleAr: "المستخدمين",
        path: PATH_DASHBOARD.user.users,
        icon: ICONS.user,
      },
      {
        title: "Companies",
        titleAr: "الشركات",
        path: PATH_DASHBOARD.company.home,
        icon: ICONS.banking,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: "management",
  //   items: [
  //     // USER
  //     {
  //       title: "user",
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: "profile", path: PATH_DASHBOARD.user.profile },
  //         { title: "cards", path: PATH_DASHBOARD.user.cards },
  //         { title: "list", path: PATH_DASHBOARD.user.list },
  //         { title: "create", path: PATH_DASHBOARD.user.new },
  //         { title: "edit", path: PATH_DASHBOARD.user.demoEdit },
  //         { title: "account", path: PATH_DASHBOARD.user.account },
  //       ],
  //     },
  //     // INVOICE
  //     {
  //       title: "invoice",
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: "list", path: PATH_DASHBOARD.invoice.list },
  //         { title: "details", path: PATH_DASHBOARD.invoice.demoView },
  //         { title: "create", path: PATH_DASHBOARD.invoice.new },
  //         { title: "edit", path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
