import { PATH_DASHBOARD } from "../../../routes/paths";
import SvgIconStyle from "../../../components/SvgIconStyle";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name: any) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const getEvaIcon = (name: any) => (
  <Iconify icon={name} width={24} height={24} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  sent: getEvaIcon("eva:navigation-2-fill"),
  processing: getEvaIcon("eva:monitor-fill"),
  archive: getEvaIcon("eva:archive-fill"),
  documents: getEvaIcon("eva:file-text-fill"),
  uploads: getEvaIcon("eva:cloud-upload-fill"),
  reports: getEvaIcon("eva:layers-fill"),
  balance: getEvaIcon("eva:printer-fill"),
  approval: getEvaIcon("eva:checkmark-square-2-fill"),
  review: getEvaIcon("eva:minus-square-fill"),
};

const NavConfigAdmin = [
  {
    id: 10,
    items: [
      {
        id: 1,
        title: "Main",
        titleAr: "الرئيسية",
        path: PATH_DASHBOARD.accountant.main,
        icon: ICONS.dashboard,
      },
    ],
  },

  {
    id: 20,
    subheader: "Uploads",
    subheaderAr: "المرفوعات",
    items: [
      {
        id: 1,
        title: "Uploads",
        titleAr: "الاضافة",
        path: PATH_DASHBOARD.accountant.processing,
        icon: ICONS.processing,
      },
    ],
  },
  {
    id: 21,
    subheader: "Archive",
    subheaderAr: "الأرشيف",
    items: [
      {
        id: 1,
        title: "Archive",
        titleAr: "الأرشيف",
        path: PATH_DASHBOARD.accountant.archive,
        icon: ICONS.archive,
      },
      {
        id: 2,
        title: "Documents",
        titleAr: "المستندات",
        path: PATH_DASHBOARD.accountant.documents,
        icon: ICONS.documents,
      },
    ],
  },
];

export default NavConfigAdmin;
