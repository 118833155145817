import { gql } from "@apollo/client";
export default gql`
  query getItemslist(
    $opType: Int
    $itemType: Int
    $categoryId: String
    $customerId: String
    $supplierId: String
    $contractId: String
    $start: Date
    $end: Date
  ) {
    getItemslist(
      opType: $opType
      itemType: $itemType
      categoryId: $categoryId
      customerId: $customerId
      supplierId: $supplierId
      contractId: $contractId
      start: $start
      end: $end
    ) {
      ok
      error
      count
      data {
        _id
        branch
        opId
        opType
        time
        docNo
        refNo
        refType
        itemId
        itemType
        barcode
        name
        nameAr
        desc
        size
        weight
        color
        unit
        photo
        qty
        cost
        totalCost
        price
        totalPrice
        amount
        categoryId
        categoryName
        categoryNameAr
        customerId
        customerName
        customerNameAr
        supplierId
        supplierName
        supplierNameAr
        note
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
