import { gql } from "@apollo/client";

export default gql`
  mutation deleteTadweenPayment($orderId: String) {
    deleteTadweenPayment(orderId: $orderId) {
      ok
      message
      error
    }
  }
`;
