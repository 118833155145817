import { gql } from "@apollo/client";

export default gql`
  query getCompanies {
    getCompanies {
      ok
      error
      data {
        _id

        basename
        foundationId
        systems
        users

        docscount

        usedEvents
        usedDocs
        usedUsers
        smss
        tempId
        template
        fronttemp
        custemp
        suptemp
        restemp
        emptemp
        deptemp
        tsktemp

        en
        ar

        photos

        name
        nameAr
        logo
        tel1
        tel2
        fax
        mob
        email
        website
        address
        poBox
        CR
        city
        country
        location {
          lat
          lng
        }
        locationLink
        userId
        username
        note

        header
        footer
        color

        packId
        packMonths
        packName
        packStart
        packEnd
        packCost

        createdAt
        updatedAt
      }
    }
  }
`;
