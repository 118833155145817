import { gql } from "@apollo/client";

export default gql`
  mutation createItem(
    $itemType: Int
    $barcode: String
    $disabled: Boolean
    $additionId: String
    $name: String
    $nameAr: String
    $desc: String
    $cost: Float
    $price: Float
    $unit: String
    $photo: String
    $categoryId: String
    $categoryName: String
    $categoryNameAr: String
  ) {
    createItem(
      itemType: $itemType
      barcode: $barcode
      disabled: $disabled
      additionId: $additionId
      name: $name
      nameAr: $nameAr
      desc: $desc
      cost: $cost
      price: $price
      unit: $unit
      photo: $photo
      categoryId: $categoryId
      categoryName: $categoryName
      categoryNameAr: $categoryNameAr
    ) {
      ok
      message
      error
      data
    }
  }
`;
