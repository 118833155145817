import { gql } from "@apollo/client";
export default gql`
  query getPosPurchases($start: Date, $end: Date, $supplierId: String) {
    getPosPurchases(start: $start, end: $end, supplierId: $supplierId) {
      ok
      error
      data {
        _id
        docNo

        time
        opType
        title
        desc

        total
        discount
        amount

        amountPaid # cash
        amountPaid2 # card/bank
        refNo
        refType

        invoiceitems
        attachments

        supplierId
        supplierName
        supplierNameAr

        stockId
        stockName
        stockNameAr

        userId

        createdAt
        updatedAt
      }
    }
  }
`;
