import numeral from "numeral";
import { Fragment } from "react";

// ----------------------------------------------------------------------

export function fCurrency(number: any) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fPercent(number: any) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number: any) {
  return numeral(number).format();
}

export function fShortenNumber(number: any) {
  return numeral(number).format("0.00a").replace(".00", "");
}

export function fData(number: any) {
  return numeral(number).format("0.0 b");
}
export function fNumber2(number: any) {
  return numeral(number).format("0,0.00");
}
export function fNumber3(number: any, percent = 0.8) {
  if (number === null || number === undefined || isNaN(Number(number))) {
    return null;
  }
  const [whole, decimal] = numeral(number).format("0,0.00").split(".");
  return (
    <Fragment>
      {whole}.<span style={{ fontSize: `${percent}em` }}>{decimal}</span>
    </Fragment>
  );
}
