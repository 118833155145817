import { gql } from "@apollo/client";

export default gql`
  subscription statusUpdated {
    statusUpdated {
      data
      userId
      branch
    }
  }
`;
