import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useMemo } from "react";

import {
  createDeliveryOut,
  deleteDeliveryOut,
  getDeliveryOut,
  getDeliveryOuts,
  updateDeliveryOut,
} from "../graphql/stock";

export default ({ start, end, _id }: any) => {
  const [getfins, finData] = useLazyQuery(getDeliveryOuts, {
    variables: { start, end },
    fetchPolicy: "cache-and-network",
  });

  const refetchQueries = useMemo(() => {
    const qrs = [];
    if (start && end) {
      qrs.push({
        query: getDeliveryOuts,
        variables: { start, end },
      });
    }
    return qrs;
  }, [start, end]);

  const [addDeliveryOut] = useMutation(createDeliveryOut, { refetchQueries });
  const [editDeliveryOut] = useMutation(updateDeliveryOut, { refetchQueries });
  const [removeDeliveryOut] = useMutation(deleteDeliveryOut, {
    refetchQueries,
  });

  useEffect(() => {
    if (start && end) {
      getfins();
    }
  }, [getfins, start, end]);

  const deliveryouts = finData?.data?.["getDeliveryOuts"]?.data || [];
  const refreshdeliveryouts = () => finData?.refetch();

  const deliveryoutswithitems = useMemo(() => {
    return deliveryouts.map((deliveryout: any) => {
      return {
        ...deliveryout,
        invoiceitems: deliveryout.invoiceitems
          ? JSON.parse(deliveryout.invoiceitems)
          : [],
        attachments: deliveryout.attachments
          ? JSON.parse(deliveryout.attachments)
          : [],
      };
    });
  }, [deliveryouts]);

  // ======================

  const [getf, fData] = useLazyQuery(getDeliveryOut, {
    variables: { _id },
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (_id) {
      getf();
    }
  }, [_id]);
  const deliveryout = fData?.data?.["getDeliveryOut"]?.data || null;
  const deliveryoutwithitems = useMemo(() => {
    if (!deliveryout) return null;
    return {
      ...deliveryout,
      invoiceitems: deliveryout.invoiceitems
        ? JSON.parse(deliveryout.invoiceitems)
        : [],
      attachments: deliveryout.attachments
        ? JSON.parse(deliveryout.attachments)
        : [],
    };
  }, [deliveryout]);

  return {
    deliveryouts,
    deliveryoutwithitems,
    addDeliveryOut,
    editDeliveryOut,
    removeDeliveryOut,
    refreshdeliveryouts,
    deliveryoutswithitems,
  };
};
