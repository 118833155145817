import { daystoadd } from "../constants";
import { fDate, moneyFormatEmpty, opTypeFullFormater } from "./formatTime";

export function calculateDaysLeft(dueDate: any) {
  const dueDateTime = new Date(dueDate);
  dueDateTime.setDate(dueDateTime.getDate() + daystoadd);
  const currentDate = new Date();
  const timeDiff = dueDateTime.getTime() - currentDate.getTime();
  const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return daysLeft;
}
export const getStartEndDayForDate = (d: any) => {
  const start = d ? new Date(d) : new Date();
  const end = d ? new Date(d) : new Date();
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);
  return { start, end };
};

export const getStartEndMonthForDate = (d: any) => {
  const date = new Date(d);
  const y = date.getFullYear();
  const m = date.getMonth();
  const start = new Date(y, m, 1, 0, 0, 0, 0);
  const end = new Date(y, m + 1, 0, 23, 59, 59, 999);
  return { start, end };
};
export const getStartEndMonth = (month: any) => {
  const date = new Date();
  if (month || month === 0) date.setMonth(month);
  const y = date.getFullYear();
  const m = date.getMonth();
  const start = new Date(y, m, 1, 0, 0, 0, 0);
  const end = new Date(y, m + 1, 0, 23, 59, 59, 999);
  return { start, end };
};
export const getStartEndYear = () => {
  const date = new Date();
  const y = date.getFullYear();
  const start = new Date(y, 0, 1, 0, 0, 0, 0);
  const end = new Date(y, 11 + 1, 0, 23, 59, 59, 999);
  return { start, end };
};
export const getStartEndYearForDate = (d: any) => {
  const date = new Date(d);
  const y = date.getFullYear();
  const start = new Date(y, 0, 1, 0, 0, 0, 0);
  const end = new Date(y, 11, 31, 23, 59, 59, 999);
  return { start, end };
};

export const getReadyRows = (kaids: any, isRTL: any) => {
  if (!kaids || kaids.length === 0) return kaids;
  const rk = kaids.map((k: any) => {
    return {
      ...k,
      opTime: fDate(k.opTime),
      opType: opTypeFullFormater(k.opType, isRTL),
      account: isRTL ? k.accNameAr : k.accName,
      debit: moneyFormatEmpty(k.debit),
      credit: moneyFormatEmpty(k.credit),
    };
  });
  return rk;
};
export const getReadyCustRows = (kaids: any, isRTL: any) => {
  if (!kaids || kaids.length === 0) return kaids;
  const rk = kaids.map((k: any) => {
    return {
      ...k,
      opTime: fDate(k.opTime),
      opType: opTypeFullFormater(k.opType, isRTL),
      account: isRTL ? k.accNameAr : k.accName,
      customer: isRTL ? k.customerNameAr : k.customerName,
      debit: moneyFormatEmpty(k.debit),
      credit: moneyFormatEmpty(k.credit),
    };
  });
  return rk;
};
export const getReadySuppRows = (kaids: any, isRTL: any) => {
  if (!kaids || kaids.length === 0) return kaids;
  const rk = kaids.map((k: any) => {
    return {
      ...k,
      opTime: fDate(k.opTime),
      opType: opTypeFullFormater(k.opType, isRTL),
      account: isRTL ? k.accNameAr : k.accName,
      supplier: isRTL ? k.supplierNameAr : k.supplierName,
      debit: moneyFormatEmpty(k.debit),
      credit: moneyFormatEmpty(k.credit),
    };
  });
  return rk;
};

// csv files format
const convertJSONtoCSV = (items: any) => {
  const replacer = (_: any, value: any) =>
    value === null
      ? ""
      : value instanceof Date
      ? value.toLocaleString()
      : value.toString(); // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  const csv = [
    header.join(","),
    ...items.map((row: any) =>
      header.map((fn) => JSON.stringify(row[fn], replacer)).join(",")
    ),
  ].join("\r\n");
  return "\uFEFF" + csv;
};

export const downloadCSVFile = (data: any, fileName = "data") => {
  if (!data) return;
  let rdata: any;
  rdata = data.map(
    ({
      __typename,
      _id,
      opId,
      branch,
      accType,
      accCode,
      accName,
      accNameAr,
      kaidType,
      updatedAt,
      createdAt,
      amount,
      userId,
      ...rest
    }: any) => ({ ...rest })
  );
  const csv = convertJSONtoCSV(rdata);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const downloadCSV = ({ name, rows, columns }: any) => {
  if (!rows || !columns) return;
  let columnNames = columns
    .filter((column: any) => column.id !== "menu")
    .map((column: any) => column.id);
  let rdata: any;
  rdata = rows.map((item: any) => {
    let newItem: any = {};
    columnNames.forEach((column: any) => {
      newItem[column] = item[column];
    });
    return newItem;
  });
  const csv = convertJSONtoCSV(rdata);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = name + ".csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const subscribePushToken = async (user: any, checked: any) => {
  if (!user?.publicKey) {
    return undefined;
  }
  let sw = await navigator.serviceWorker.ready;
  let sub = await sw.pushManager.getSubscription(); // TODO: use sub to unsubscribe on server
  if (checked) {
    if (!sub) {
      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: user?.publicKey,
      });
      return JSON.stringify(push);
    } else {
      await sub.unsubscribe();
      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: user?.publicKey,
      });
      return JSON.stringify(push);
    }
  } else {
    if (sub) {
      await sub.unsubscribe();
      return undefined;
    } else {
      return undefined;
    }
  }
};

export const getDefValue = (id: any, list: any) => {
  return id && list?.length > 0
    ? list?.find((sitem: any) => sitem._id === id)
    : null;
};
export const getDefValueTag = (id: any, list: any) => {
  return id && list?.length > 0
    ? list?.find((sitem: any) => sitem.value === id)
    : null;
};
export const getReturnItem = (res: any, mutation: string) => {
  return res?.data?.[mutation]?.ok ? JSON.parse(res.data[mutation].data) : null;
};
export const toFixed2 = (num: number): number => {
  if (!num) return 0;
  return parseFloat(num.toFixed(2));
};
export const nameToColor = (name: string, s = 40, l = 70) => {
  if (!name) return "#f4f4f4";
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};

export const fullscreen = () => document.body.requestFullscreen();
export const exitfullscreen = () => document.exitFullscreen();
// export const nanoid = (length = 21) => {
//   const characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   let result = "";
//   for (let i = 0; i < length; i++) {
//     result += characters.charAt(Math.floor(Math.random() * characters.length));
//   }
//   return result;
// };

export const nanoid = (length = 21) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";
  const randomValues = new Uint8Array(length);
  crypto.getRandomValues(randomValues);
  for (let i = 0; i < length; i++) {
    result += characters.charAt(randomValues[i] % charactersLength);
  }
  return result;
};

export function extractPdfFileName(url: string): string {
  const filenameWithUniqueId = url.substring(url.lastIndexOf("/") + 1);
  const fileName = filenameWithUniqueId.substring(
    filenameWithUniqueId.lastIndexOf("_") + 1,
    filenameWithUniqueId.lastIndexOf(".")
  );

  return fileName;
}
