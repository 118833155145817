import { gql } from "@apollo/client";
export default gql`
  query getPosInvoices($start: Date, $end: Date, $customerId: String) {
    getPosInvoices(start: $start, end: $end, customerId: $customerId) {
      ok
      error
      data {
        _id
        docNo

        time
        opType
        title
        desc

        total
        discount
        amount

        amountPaid # cash
        amountPaid2 # card/bank
        refNo
        refType

        invoiceitems
        attachments

        customerId
        customerName
        customerNameAr

        stockId
        stockName
        stockNameAr

        userId

        createdAt
        updatedAt
      }
    }
  }
`;
