import { gql } from "@apollo/client";

export default gql`
  subscription approveAdded {
    approveAdded {
      data
      userId
      branch
    }
  }
`;
