import { gql } from "@apollo/client";

export default gql`
  query getMyDueTadweenInvoices {
    getMyDueTadweenInvoices {
      ok
      message
      data {
        _id
        autoNo
        time
        title
        desc
        receiptId
        customerId
        customerName
        customerNameAr
        customerPhone
        items {
          name
          qty
          price
          total
        }
        total
        discount
        amount
        month
        year
        isPaid
        userId
        note
        createdAt
        updatedAt
      }
      error
    }
  }
`;
