import { gql } from "@apollo/client";

export default gql`
  mutation createStock(
    $name: String
    $nameAr: String
    $desc: String
    $disabled: Boolean
    $additionId: String
    $note: String
  ) {
    createStock(
      name: $name
      nameAr: $nameAr
      desc: $desc
      disabled: $disabled
      additionId: $additionId
      note: $note
    ) {
      ok
      message
      data
      error
    }
  }
`;
