import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

interface GeneralModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  maxWidth: any;
  bgcolor?: any;
}

const GeneralModal: React.FC<GeneralModalProps> = ({
  open,
  onClose,
  title,
  children,
  maxWidth = "md",
  bgcolor,
}) => {
  return (
    <Dialog
      sx={{ bgcolor: bgcolor }}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle sx={{ bgcolor }}>{title}</DialogTitle>
      <DialogContent sx={{ bgcolor }}>{children}</DialogContent>
    </Dialog>
  );
};

export default GeneralModal;
