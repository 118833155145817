import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Iconify from "../../components/Iconify";

const NumericKeypadDialog = ({
  value,
  open,
  onClose,
  onSubmit,
  isRTL,
}: any) => {
  const [localvalue, setLocalValue] = useState("");

  const handleNumberClick = (num: any) => {
    if (num === "C") {
      setLocalValue("");
      return;
    }
    if (num === "." && localvalue.includes(".")) return;
    if (isNaN(num) && num !== ".") return; // Ignore non-numeric input except the dot

    setLocalValue((prevValue: any) => prevValue + num);
  };
  const handleBackspaceClick = () => {
    setLocalValue((prevValue: any) => prevValue.slice(0, -1));
  };

  useEffect(() => {
    setLocalValue(value ? `${value}` : "");
  }, [value, open]);

  const handleEnter = () => {
    onSubmit(localvalue);
    onClose();
    setLocalValue("");
  };

  const handleCancel = () => {
    onClose();
    setLocalValue("");
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!open) return; // Ignore keydown events when the dialog is not open
      const key: any = event.key;
      if (!isNaN(key) || key === ".") {
        handleNumberClick(key);
      } else if (key === "Backspace") {
        handleBackspaceClick();
      } else if (key === "Enter") {
        handleEnter();
        event.preventDefault(); // Prevent the event from being captured by other elements
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, handleNumberClick, handleBackspaceClick, handleEnter]);

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent>
        <TextField
          value={localvalue}
          InputProps={{
            readOnly: true,
            style: {
              cursor: "default", // Change the cursor to the default cursor
              caretColor: "transparent", // Make the text cursor (caret) transparent
            },
          }}
          variant="outlined"
          fullWidth
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "3rem",
              fontWeight: "bold",
              padding: "10px 20px",
            },
          }}
        />
        <Grid
          container
          spacing={3}
          sx={{ mt: 2, flexDirection: isRTL ? "row-reverse" : "row" }}
        >
          <Grid item xs={9}>
            <Grid
              container
              spacing={1}
              sx={{ mt: 2, flexDirection: isRTL ? "row-reverse" : "row" }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "."].map((num, index) => {
                const xs = num === 0 ? 8 : 4;
                return (
                  <Grid item xs={xs} key={index}>
                    <Button
                      fullWidth
                      sx={{ height: 75 }}
                      color={num === "C" ? "error" : "primary"}
                      variant={"outlined"}
                      onClick={() => handleNumberClick(num)}
                    >
                      <Typography variant="h3">{num}</Typography>
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
            {/* <Grid container spacing={1} sx={{ mt: 2 }}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, "C", 0, "."].map((num, index) => {
                return (
                  <Grid item xs={4} key={index}>
                    <Button
                      fullWidth
                      sx={{ height: 75 }}
                      color={num === "C" ? "error" : "primary"}
                      variant={"outlined"}
                      onClick={() => handleNumberClick(num)}
                    >
                      <Typography variant="h3">{num}</Typography>
                    </Button>
                  </Grid>
                );
              })}
            </Grid> */}
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              spacing={0}
              sx={{
                mt: 3,
                mb: 3,
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid item xs={3}>
                <Button
                  fullWidth
                  sx={{
                    height: 75,
                    width: 75,
                  }}
                  color="error"
                  variant="outlined"
                  onClick={() => handleNumberClick("C")}
                >
                  <Typography variant="h3">C</Typography>
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  sx={{ height: 75, mt: -0.4, width: 75 }}
                  variant="outlined"
                  onClick={handleBackspaceClick}
                >
                  <Iconify sx={{ fontSize: 30 }} icon="mdi:backspace" />
                </Button>
              </Grid>
              <Grid item xs={6} sx={{ flex: "1" }}>
                <Button
                  fullWidth
                  sx={{ height: "90%", mt: -0.9, width: 75 }}
                  variant="contained"
                  color="primary"
                  onClick={handleEnter}
                >
                  <Typography variant="h3">OK</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NumericKeypadDialog;
