import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  createCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from "../graphql/item";
import { itemTypes } from "../constants";

export default () => {
  const [servicecategories, setServicecategories]: any = useState([]);
  const [productcategories, setProductcategories]: any = useState([]);

  const [getDeparts, depData]: any = useLazyQuery(getCategories);

  const [addCategory] = useMutation(createCategory, {
    refetchQueries: [{ query: getCategories }],
  });
  const [editCategory] = useMutation(updateCategory, {
    refetchQueries: [{ query: getCategories }],
  });
  const [removeCategory] = useMutation(deleteCategory, {
    refetchQueries: [{ query: getCategories }],
  });

  useEffect(() => {
    getDeparts();
  }, [getDeparts]);

  useEffect(() => {
    if (depData?.data?.["getCategories"]?.data) {
      const categories = depData?.data?.["getCategories"]?.data || [];
      const servicecategories = categories
        .filter((category: any) => category.catType === itemTypes.service)
        .sort((a: any, b: any) => a.name.localeCompare(b.name));
      const productcategories = categories
        .filter((category: any) => category.catType === itemTypes.product)
        .sort((a: any, b: any) => a.name.localeCompare(b.name));
      setServicecategories(servicecategories);
      setProductcategories(productcategories);
    }
  }, [depData]);

  const categories = depData?.data?.["getCategories"]?.data || [];
  const refreshcategory = () => depData?.refetch();
  return {
    categories,
    servicecategories,
    productcategories,
    refreshcategory,
    addCategory,
    editCategory,
    removeCategory,
  };
};
