import { Box, Card, Grid, Typography } from "@mui/material";

function CardBox({ title, children, color = "#826AF915", sx }: any) {
  return (
    <Grid sx={sx} item xs={12} md={12}>
      <Card sx={{ bgcolor: color }}>
        <Box sx={{ p: 1, pl: 3 }}>
          <Typography
            color="primary"
            sx={{ fontSize: 18, fontWeight: "bold", mb: 3 }}
          >
            {title}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ bgcolor: "#fff", borderRadius: 2, pb: 2, pr: 2 }}
          >
            {children}
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
}

export default CardBox;
