import { gql } from "@apollo/client";

export default gql`
  mutation checkEmail($email: String) {
    checkEmail(email: $email) {
      ok
      message
      data
      error
    }
  }
`;
