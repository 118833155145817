import { useEffect, useState } from "react";

const useLogo = (logo: string) => {
  const [logoData, setLogoData] = useState<string | null>(null);
  useEffect(() => {
    if (logo) {
      const secureLogo = logo.replace(/^http:/, "https:");

      fetch(secureLogo)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setLogoData(reader.result as string);
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error("Error loading logo:", error);
          setLogoData(null);
        });
    }
  }, [logo]);

  return logoData;
};

export default useLogo;
