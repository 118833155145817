import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, IconButton, Tooltip } from "@mui/material";
import Iconify from "./Iconify";

export default function RemoveIcon({
  onSubmit,
  isRTL,
  title = "",
  tooltip,
  icon,
}: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    onSubmit();
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={tooltip}>
        {icon ? (
          <IconButton onClick={handleClickOpen} size="small">
            <Iconify sx={{ color: "error.light" }} icon="eva:trash-2-outline" />
          </IconButton>
        ) : (
          <Button
            onClick={handleClickOpen}
            sx={{ color: "error.main" }}
            startIcon={
              <Iconify
                sx={{ color: "error.main" }}
                icon="eva:trash-2-outline"
              />
            }
          >
            {title}
          </Button>
        )}
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {isRTL ? "حذف" : "Remove"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isRTL
              ? "هل انت متأكد انك تريد الحذف "
              : "Are you sure want to remove?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {isRTL ? "إلغاء" : "Cancel"}
          </Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            {isRTL ? "حذف" : "Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
