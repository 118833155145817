import { gql } from "@apollo/client";

export default gql`
  mutation updateDeliveryOut(
    $_id: String
    $opType: Int
    $time: Date
    $title: String
    $desc: String
    $cost: Float
    $total: Float
    $amount: Float
    $invoiceitems: String
    $attachments: String
    $supplierId: String
    $supplierName: String
    $supplierNameAr: String
    $customerId: String
    $customerName: String
    $customerNameAr: String
    $stockId: String
    $stockName: String
    $stockNameAr: String
  ) {
    updateDeliveryOut(
      _id: $_id
      opType: $opType
      time: $time
      title: $title
      desc: $desc
      cost: $cost
      total: $total
      amount: $amount
      invoiceitems: $invoiceitems
      attachments: $attachments
      supplierId: $supplierId
      supplierName: $supplierName
      supplierNameAr: $supplierNameAr
      customerId: $customerId
      customerName: $customerName
      customerNameAr: $customerNameAr
      stockId: $stockId
      stockName: $stockName
      stockNameAr: $stockNameAr
    ) {
      ok
      message
      data
      error
    }
  }
`;
