import { gql } from "@apollo/client";

export default gql`
  query getItems {
    getItems {
      ok
      message
      error
      data {
        _id
        branch
        autoNo
        itemType
        barcode
        disabled
        additionId

        name
        nameAr
        desc

        categoryId
        categoryName
        categoryNameAr

        stocks {
          stockId
          stockName
          stockNameAr
          qty
          cost
          price
        }

        qty
        cost
        price
        unit
        size
        weight
        color
        photo

        userId

        createdAt
        updatedAt
      }
    }
  }
`;
