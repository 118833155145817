// @mui
import { styled, useTheme } from "@mui/material/styles";
import { List, Box, ListSubheader, Divider, ListItem } from "@mui/material";
//
import { NavListRoot } from "./NavList";
import useRoles from "../../../hooks/useRoles";
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from "./style";
import Iconify from "../../Iconify";
import useLocales from "../../../hooks/useLocales";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

export const ListSubheaderStyle: any = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  isRTL,
  waitingcount,
  reviewcount,
  processingcount,
  isDesktop,
  ...other
}: any) {
  const theme = useTheme();
  const { isBranchsAdmin, isAdmin, isEditor, tempoptions } = useRoles();
  const { onChangeLang } = useLocales();
  const navigate = useNavigate();

  const { user, logout, view, changeView }: any = useAuth();
  const isAccountant = user?.isAccountant;

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/", { replace: true });
    } catch (error) {
      console.error(error);
    }
  };
  const handleChangeView = () => {
    if (view === "isAccountant") {
      changeView(null);
      navigate(PATH_DASHBOARD.general.main, { replace: true });
    } else {
      changeView("isAccountant");
      navigate(PATH_DASHBOARD.accountant.main, { replace: true });
    }
  };
  const title =
    view === "isAccountant"
      ? isRTL
        ? "الانتقال للشركة"
        : "Company View"
      : isRTL
      ? "الانتقال للحسابات"
      : "Accountant View";
  return (
    <Box {...other}>
      {isAccountant && (
        <List disablePadding sx={{ px: 2, pt: -1 }}>
          <ListItemStyle onClick={handleChangeView}>
            <ListItemIconStyle style={{ color: theme.palette.warning.light }}>
              <Iconify icon={"eva:code-fill"} width={24} height={24} />
            </ListItemIconStyle>
            <ListItemTextStyle
              disableTypography
              primary={title}
              isCollapse={isCollapse}
              style={{ color: theme.palette.warning.light }}
            />
          </ListItemStyle>
        </List>
      )}
      {navConfig.map((group: any, i: any) => {
        if (!isBranchsAdmin && group?.id === 5) return <></>;
        if (!isAdmin && group?.id === 4) return <></>;
        return (
          <List key={group.id} disablePadding sx={{ px: 2, pt: 1 }}>
            {isDesktop && (
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
                style={{ color: theme.palette.secondary.light, fontSize: 14 }}
              >
                {isRTL ? group.subheaderAr : group.subheader}
              </ListSubheaderStyle>
            )}
            {i !== 0 && <Divider></Divider>}
            {group.items.map((list: any) => {
              if (!tempoptions?.approval && group.id === 2 && list?.id === 1)
                return <></>;
              // if (!isAdmin && group.id === 2 && list?.id === 2) return <></>;
              if (!isAdmin && group.id === 2 && list?.id === 4) return <></>;
              if (!isAdmin && group.id === 4) return <></>;
              if (!isEditor && group.id === 3 && list?.id === 2) return <></>;
              return (
                <NavListRoot
                  isRTL={isRTL}
                  key={list.title}
                  list={list}
                  isCollapse={isCollapse}
                  waitingcount={waitingcount}
                  reviewcount={reviewcount}
                  processingcount={processingcount}
                />
              );
            })}
          </List>
        );
      })}
      <Divider></Divider>
      <List disablePadding sx={{ px: 2, pt: -1 }}>
        <ListItemStyle onClick={() => onChangeLang(isRTL ? "en" : "ar")}>
          <ListItemIconStyle
            style={{ color: theme.palette.primary.contrastText }}
          >
            <Iconify icon={"eva:globe-fill"} width={24} height={24} />
          </ListItemIconStyle>
          <ListItemTextStyle
            disableTypography
            primary={isRTL ? "اللغة (English)" : "Language (عربي)"}
            isCollapse={isCollapse}
            style={{ color: theme.palette.primary.contrastText }}
          />
        </ListItemStyle>

        <ListItemStyle onClick={handleLogout}>
          <ListItemIconStyle
            style={{ color: theme.palette.primary.contrastText }}
          >
            <Iconify icon={"eva:log-out-fill"} width={24} height={24} />
          </ListItemIconStyle>
          <ListItemTextStyle
            disableTypography
            primary={isRTL ? "تسجيل خروج" : "Logout"}
            isCollapse={isCollapse}
            style={{ color: theme.palette.primary.contrastText }}
          />
        </ListItemStyle>
      </List>
    </Box>
  );
}
