import { gql } from "@apollo/client";

export default gql`
  mutation trialToSubscribtion($branchId: String, $packId: Int) {
    trialToSubscribtion(branchId: $branchId, packId: $packId) {
      ok
      message
      data
      error
    }
  }
`;
