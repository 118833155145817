import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import { isValidToken, setSession } from "../utils/jwt";
import { useMutation } from "@apollo/client";
import {
  login as glogin,
  me,
  loginGoogle,
  changeUserBranch,
} from "../graphql/all";
import { getStoreItem, setStoreItem } from "../redux/helpers";
import { client } from "../graphql/client";
import { googleLogout } from "@react-oauth/google";
import signup from "../graphql/user/signup";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  hasCompany: null,
  template: null,
  user: null,
  version: null,
  notify: null,
  view: null,
};

const handlers: any = {
  INITIALIZE: (state: any, action: any) => {
    const { isAuthenticated, hasCompany, user, template } = action.payload;
    return {
      ...state,
      isAuthenticated,
      hasCompany,
      isInitialized: true,
      template: template ? JSON.parse(template) : null,
      user,
    };
  },
  LOGIN: (state: any, action: any) => {
    const { user, hasCompany, template } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      hasCompany,
      template: template ? JSON.parse(template) : null,
      user,
    };
  },
  LOGOUT: (state: any) => ({
    ...state,
    isAuthenticated: false,
    hasCompany: null,
    template: null,
    user: null,
  }),
  REGISTER: (state: any, action: any) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      hasCompany: null,
      template: null,
      user,
    };
  },
  VERSION: (state: any, action: any) => {
    const { version } = action.payload;
    return {
      ...state,
      version,
    };
  },
  NOTIFY: (state: any, action: any) => {
    const { notify } = action.payload;
    return {
      ...state,
      notify,
    };
  },
  VIEW: (state: any, action: any) => {
    const { view } = action.payload;
    return {
      ...state,
      view,
    };
  },
};

const reducer = (state: any, action: any) =>
  handlers?.[action?.type] ? handlers?.[action?.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  googin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  refetchme: () => Promise.resolve(),
  changeBranch: () => Promise.resolve(),
  updateversion: () => Promise.resolve(),
  updatenotify: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }: any) {
  const authStoreState = getStoreItem("authStore", initialState);

  const [state, dispatch] = useReducer(
    reducer,
    authStoreState ? authStoreState : initialState
  );

  const [getme] = useMutation(me);
  const [dologin] = useMutation(glogin);
  const [googlelogin] = useMutation(loginGoogle);
  const [adduser] = useMutation(signup);
  const [switchBranch] = useMutation(changeUserBranch);

  useEffect(() => {
    setStoreItem("authStore", state);
  }, [state]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        if (token && isValidToken(token)) {
          const userData = await getme({});
          if (userData?.data?.me?.ok === true) {
            const { data, hasCompany, accessToken, template } =
              userData.data.me;
            const user = {
              ...data,
              roles: JSON.parse(data.roles),
            };
            setSession(accessToken);
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                hasCompany,
                template,
                user,
              },
            });
          } else {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
                hasCompany: null,
                template: null,
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              hasCompany: null,
              template: null,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            hasCompany: null,
            template: null,
            user: null,
          },
        });
      }
    };
    if (state?.isAuthenticated !== true) {
      initialize();
    }
  }, []);

  const login = async (username: any, password: any) => {
    const userData = await dologin({ variables: { username, password } });
    if (userData?.data?.login?.ok === true) {
      const { data, hasCompany, accessToken, template } = userData.data.login;
      console.log("accessToken", accessToken);
      const user = {
        ...data,
        roles: JSON.parse(data.roles),
      };
      await client.resetStore();
      setSession(accessToken);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          template,
          hasCompany,
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      return userData;
    }
  };

  const changeBranch = async (brn: any) => {
    const userData = await switchBranch({ variables: { branch: brn } });
    if (userData?.data?.changeUserBranch?.ok === true) {
      const { data, accessToken, hasCompany, template } =
        userData.data.changeUserBranch;
      const user = {
        ...data,
        roles: JSON.parse(data.roles),
      };
      await client.resetStore();
      setSession(accessToken);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          template,
          hasCompany,
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const googin = async ({ email, name, avatar }: any) => {
    const userData = await googlelogin({ variables: { email, name, avatar } });
    if (userData?.data?.loginGoogle?.ok === true) {
      const { data, hasCompany, accessToken, template } =
        userData.data.loginGoogle;
      const user = {
        ...data,
        roles: JSON.parse(data.roles),
      };
      await client.resetStore();
      setSession(accessToken);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          template,
          hasCompany,
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const register = async ({ email, password, name }: any) => {
    const userData = await adduser({
      variables: { username: email, password, name },
    });
    if (userData?.data?.signup?.ok === true) {
      const { data, accessToken, template } = userData.data.signup;
      const user = {
        ...data,
        roles: JSON.parse(data.roles),
      };
      await client.resetStore();
      setSession(accessToken);
      dispatch({
        type: "REGISTER",
        payload: {
          user,
          template,
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      return userData;
    }
  };

  const refetchme = async () => {
    const token = localStorage.getItem("accessToken");
    if (token && isValidToken(token)) {
      const userData = await getme({});
      if (userData?.data?.me?.ok === true) {
        const { data, hasCompany, accessToken, template } = userData.data.me;
        const user = {
          ...data,
          roles: JSON.parse(data.roles),
        };
        await client.resetStore();
        setSession(accessToken);
        dispatch({
          type: "LOGIN",
          payload: {
            user,
            template,
            hasCompany,
          },
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        return userData;
      }
    }
  };

  const logout = async () => {
    setSession(null);
    googleLogout();
    dispatch({ type: "LOGOUT" });
  };
  const updateversion = (version: string, out = true) => {
    dispatch({
      type: "VERSION",
      payload: {
        version,
      },
    });
    if (out) {
      logout();
    }
  };
  const updatenotify = (notify: boolean) => {
    dispatch({
      type: "NOTIFY",
      payload: {
        notify,
      },
    });
  };
  const changeView = (view: boolean) => {
    dispatch({
      type: "VIEW",
      payload: {
        view,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        googin,
        logout,
        register,
        refetchme,
        changeBranch,
        updateversion,
        updatenotify,
        changeView,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
