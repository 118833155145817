import { Suspense, lazy, useEffect } from "react";
import {
  Navigate,
  useRoutes,
  useLocation,
  useNavigate,
  Outlet,
} from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import {
  PATH_AFTER_LOGIN,
  PATH_AFTER_LOGIN_ACCOUNT,
  PATH_AFTER_LOGIN_NEW,
} from "../config";
import LoadingScreen from "../components/LoadingScreen";
import Privacy from "../pages/general/Privacy";
import Terms from "../pages/general/Terms";
import useAuth from "../hooks/useAuth";
import { PurchaseProvider, SalesProvider, StockProvider } from "../contexts";

const Loadable = (Component: any) => (props: any) => {
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router({ linkerror }: any) {
  const { user, hasCompany, logout, view }: any = useAuth();
  const navigate = useNavigate();
  const isAccountant = user?.isAccountant;
  const PATH_AFTER =
    isAccountant && view === "isAccountant"
      ? PATH_AFTER_LOGIN_ACCOUNT
      : PATH_AFTER_LOGIN;

  useEffect(() => {
    if (linkerror === true) {
      handleLinkError();
    }
  }, [linkerror]);

  const handleLinkError = async () => {
    await logout();
    navigate("/", { replace: true });
  };

  return useRoutes([
    { path: "privacy", element: <Privacy /> },
    { path: "terms", element: <Terms /> },
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: hasCompany
        ? [
            {
              element: (
                <Navigate
                  to={hasCompany ? PATH_AFTER : PATH_AFTER_LOGIN_NEW}
                  replace
                />
              ),
              index: true,
            },
            { path: "company", element: <CompanyHome /> },
            { path: "packages", element: <CompanyPackages /> },
            { path: "upgrade", element: <CompanyUpgrade /> },
            { path: "checkoutnewcomp", element: <Checkout /> },
            { path: "checkoutupgrade", element: <CheckoutUpgrade /> },
            { path: "checkoutinvoice", element: <CheckoutImvoice /> },
            { path: "main", element: <GeneralApp /> },
            { path: "uploaddocs", element: <UploadDocs /> },
            { path: "documents", element: <DocumentList /> },
            { path: "waiting", element: <WaitingList /> },
            { path: "approval", element: <WaitingList /> },
            { path: "review", element: <DocumentsReview /> },
            { path: "processing", element: <DocumentsProcessing /> },
            { path: "archive", element: <ArchiveList /> },
            { path: "docsreport", element: <GDocumentsList /> },
            { path: "financereport", element: <FinanceReport /> },
            { path: "incomereport", element: <IncomeReport /> },
            { path: "expensesreport", element: <ExpensesReport /> },
            { path: "purchasereport", element: <PurchaseReport /> },
            { path: "customersreport", element: <CustomersReport /> },
            { path: "customersraseeds", element: <CustomersRaseeds /> },
            { path: "suppliersreport", element: <SuppliersReport /> },
            { path: "suppliersraseeds", element: <SuppliersRaseeds /> },
            { path: "profitabilityreport", element: <ProfitabilityReport /> },
            { path: "budgetreport", element: <BudgetReport /> },
            {
              path: "invoice",
              children: [
                {
                  element: <Navigate to="/dashboard/invoice/list" replace />,
                  index: true,
                },
                // { path: "list", element: <InvoiceList /> },
                { path: ":id", element: <InvoiceDetails /> },
              ],
            },
            {
              path: "documents",
              children: [{ path: ":id", element: <UploadDocDetails /> }],
            },
            {
              path: "waiting",
              children: [{ path: ":id", element: <WaitingDocDetails /> }],
            },
            {
              path: "approval",
              children: [{ path: ":id", element: <WaitingDocDetails /> }],
            },
            {
              path: "review",
              children: [{ path: ":id", element: <ReviewDocDetails /> }],
            },
            {
              path: "processing",
              children: [{ path: ":id", element: <ProcessDocDetails /> }],
            },
            {
              path: "archive",
              children: [{ path: ":id", element: <ArchiveDocDetails /> }],
            },
            {
              path: "docsreport",
              children: [{ path: ":id", element: <GeneralDocDetails /> }],
            },
            {
              path: "user",
              children: [
                {
                  element: <Navigate to="/dashboard/user/users" replace />,
                  index: true,
                },
                { path: "users", element: <Users /> },
                { path: "profile", element: <UserProfile /> },
                { path: "new", element: <UserCreate /> },
                { path: ":name/edit", element: <UserCreate /> },
                { path: "verify", element: <VerifyCode /> },
              ],
            },
            {
              path: "accountant",
              children: [
                {
                  element: <Navigate to="/dashboard/accountant/main" replace />,
                  index: true,
                },
                { path: "main", element: <AccountMain /> },
                { path: "processing", element: <UploadProcessing /> },
                { path: "review", element: <DocumentsReview /> },
                { path: "archive", element: <ArchiveList /> },
                { path: "documents", element: <GDocumentsList /> },
                {
                  path: "documents",
                  children: [{ path: ":id", element: <GeneralDocDetails /> }],
                },
                {
                  path: "review",
                  children: [{ path: ":id", element: <ReviewDocDetails /> }],
                },
                {
                  path: "processing",
                  children: [
                    { path: ":id", element: <ProcessDocDetails /> },
                    { path: ":id/edit", element: <ProcessCreateDoc /> },
                  ],
                },
                {
                  path: "archive",
                  children: [{ path: ":id", element: <ArchiveDocDetails /> }],
                },
              ],
            },
            {
              path: "pos",

              children: [
                {
                  element: <Navigate to="/dashboard/pos/root" replace />,
                  index: true,
                },
                { path: "root", element: <Pos /> },
                { path: "bills", element: <Bills /> },
                { path: "dailysales", element: <DailySales /> },
                { path: "posreport", element: <POSReport /> },
                {
                  path: "bill",
                  children: [
                    { path: ":id", element: <BillView /> },
                    { path: ":id/edit", element: <BillCreate /> },
                  ],
                },
              ],
            },
            {
              path: "items",
              children: [
                {
                  element: (
                    <Navigate to="/dashboard/items/categories" replace />
                  ),
                  index: true,
                },
                {
                  path: "category",
                  children: [
                    {
                      element: (
                        <Navigate
                          to="/dashboard/items/category/categories"
                          replace
                        />
                      ),
                      index: true,
                    },
                    { path: "categories", element: <Categories /> },
                    { path: "new", element: <CategoryCreate /> },
                    { path: ":id", element: <CategoryView /> },
                    { path: ":id/edit", element: <CategoryCreate /> },
                  ],
                },
                {
                  path: "product",
                  children: [
                    {
                      element: (
                        <Navigate
                          to="/dashboard/items/product/products"
                          replace
                        />
                      ),
                      index: true,
                    },
                    { path: "products", element: <Products /> },
                    { path: "new", element: <ProductCreate /> },
                    { path: ":id", element: <ProductView /> },
                    { path: ":id/edit", element: <ProductCreate /> },
                  ],
                },
                {
                  path: "service",
                  children: [
                    {
                      element: (
                        <Navigate
                          to="/dashboard/items/service/services"
                          replace
                        />
                      ),
                      index: true,
                    },
                    { path: "services", element: <Services /> },
                    { path: "new", element: <ServiceCreate /> },
                    { path: ":id", element: <ServiceView /> },
                    { path: ":id/edit", element: <ServiceCreate /> },
                  ],
                },
              ],
            },
            {
              path: "sales",
              element: (
                <SalesProvider>
                  <Outlet />
                </SalesProvider>
              ),
              children: [
                {
                  element: <Navigate to="/dashboard/sales/customer" replace />,
                  index: true,
                },
                {
                  path: "customer",
                  children: [
                    {
                      element: (
                        <Navigate
                          to="/dashboard/sales/customer/customers"
                          replace
                        />
                      ),
                      index: true,
                    },
                    { path: "customers", element: <Customers /> },
                    { path: "new", element: <CustomerCreate /> },
                    { path: ":id", element: <CustomerView /> },
                    { path: ":id/edit", element: <CustomerCreate /> },
                  ],
                },
                {
                  path: "invoice",
                  children: [
                    {
                      element: (
                        <Navigate
                          to="/dashboard/sales/invoice/invoices"
                          replace
                        />
                      ),
                      index: true,
                    },
                    { path: "invoices", element: <Invoices /> },
                    { path: "new", element: <InvoiceCreate /> },
                    { path: ":id", element: <InvoiceView /> },
                    { path: ":id/edit", element: <InvoiceCreate /> },
                  ],
                },
              ],
            },
            {
              path: "purchase",
              element: (
                <PurchaseProvider>
                  <Outlet />
                </PurchaseProvider>
              ),
              children: [
                {
                  element: (
                    <Navigate to="/dashboard/purchase/supplier" replace />
                  ),
                  index: true,
                },
                {
                  path: "supplier",
                  children: [
                    {
                      element: (
                        <Navigate
                          to="/dashboard/purchase/supplier/suppliers"
                          replace
                        />
                      ),
                      index: true,
                    },
                    { path: "suppliers", element: <Suppliers /> },
                    { path: "new", element: <SupplierCreate /> },
                    { path: ":id", element: <SupplierView /> },
                    {
                      path: ":id/edit",
                      element: <SupplierCreate />,
                    },
                  ],
                },
                {
                  path: "purchaseinvoice",
                  children: [
                    {
                      element: (
                        <Navigate
                          to="/dashboard/purchase/purchaseinvoice/purchaseinvoices"
                          replace
                        />
                      ),
                      index: true,
                    },
                    { path: "purchaseinvoices", element: <PurchaseInvoices /> },
                    { path: "new", element: <PurchaseInvoiceCreate /> },
                    { path: ":id", element: <PurchaseInvoiceView /> },
                    {
                      path: ":id/edit",
                      element: <PurchaseInvoiceCreate />,
                    },
                  ],
                },
              ],
            },
            {
              path: "stock",
              element: (
                <StockProvider>
                  <Outlet />
                </StockProvider>
              ),
              children: [
                {
                  element: (
                    <Navigate to="/dashboard/stock/stock/stocks" replace />
                  ),
                  index: true,
                },
                {
                  path: "stock",
                  children: [
                    {
                      element: (
                        <Navigate to="/dashboard/stock/stock/stocks" replace />
                      ),
                      index: true,
                    },
                    { path: "stocks", element: <Stocks /> },
                    { path: "new", element: <StockCreate /> },
                    { path: ":id", element: <StockView /> },
                    {
                      path: ":id/edit",
                      element: <StockCreate />,
                    },
                  ],
                },
                {
                  path: "item",
                  children: [
                    {
                      element: (
                        <Navigate to="/dashboard/stock/item/items" replace />
                      ),
                      index: true,
                    },
                    { path: "items", element: <StockProducts /> },
                    { path: ":id", element: <StockProductView /> },
                    { path: "itemreport", element: <ProductsReport /> },
                  ],
                },
                {
                  path: "deliveryin",
                  children: [
                    {
                      element: (
                        <Navigate
                          to="/dashboard/stock/deliveryin/deliveryins"
                          replace
                        />
                      ),
                      index: true,
                    },
                    { path: "deliveryins", element: <DeliveryIns /> },
                    { path: "new", element: <DeliveryInCreate /> },
                    { path: ":id", element: <DeliveryInView /> },
                    {
                      path: ":id/edit",
                      element: <DeliveryInCreate />,
                    },
                  ],
                },
                {
                  path: "deliveryout",
                  children: [
                    {
                      element: (
                        <Navigate
                          to="/dashboard/stock/deliveryout/deliveryouts"
                          replace
                        />
                      ),
                      index: true,
                    },
                    { path: "deliveryouts", element: <DeliveryOuts /> },
                    { path: "new", element: <DeliveryOutCreate /> },
                    { path: ":id", element: <DeliveryOutView /> },
                    {
                      path: ":id/edit",
                      element: <DeliveryOutCreate />,
                    },
                  ],
                },
              ],
            },
          ]
        : [
            {
              element: (
                <Navigate
                  to={hasCompany ? PATH_AFTER : PATH_AFTER_LOGIN_NEW}
                  replace
                />
              ),
              index: true,
            },
            { path: "company", element: <CompanyHome /> },
            { path: "packages", element: <CompanyPackages /> },
            { path: "upgrade", element: <CompanyUpgrade /> },
            { path: "checkoutnewcomp", element: <Checkout /> },
            { path: "checkoutupgrade", element: <CheckoutUpgrade /> },
            { path: "checkoutinvoice", element: <CheckoutImvoice /> },
            {
              path: "user",
              children: [
                {
                  element: <Navigate to="/dashboard/user/users" replace />,
                  index: true,
                },
                { path: "users", element: <Users /> },
                { path: "profile", element: <UserProfile /> },
                { path: "new", element: <UserCreate /> },
                { path: ":name/edit", element: <UserCreate /> },
                { path: "verify", element: <VerifyCode /> },
              ],
            },
          ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);

const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const AccountMain = Loadable(
  lazy(() => import("../pages/dashboard/AccountMain"))
);

const DocumentList = Loadable(
  lazy(() => import("../pages/dashboard/upload/DocumentList"))
);
const DocumentsProcessing = Loadable(
  lazy(() => import("../pages/dashboard/upload/DocumentsProcessing"))
);
const UploadProcessing = Loadable(
  lazy(() => import("../pages/dashboard/accountant/UploadProcessing"))
);
const DocumentsReview = Loadable(
  lazy(() => import("../pages/dashboard/upload/DocumentsReview"))
);
const WaitingList = Loadable(
  lazy(() => import("../pages/dashboard/upload/WaitingList"))
);
const GDocumentsList = Loadable(
  lazy(() => import("../pages/dashboard/docs/GDocumentsList"))
);
const ArchiveList = Loadable(
  lazy(() => import("../pages/dashboard/upload/ArchiveList"))
);
const UploadDocDetails = Loadable(
  lazy(() => import("../pages/dashboard/upload/UploadDocDetails"))
);
const ReviewDocDetails = Loadable(
  lazy(() => import("../pages/dashboard/upload/ReviewDocDetails"))
);
const ProcessDocDetails = Loadable(
  lazy(() => import("../pages/dashboard/upload/ProcessDocDetails"))
);
const ProcessCreateDoc = Loadable(
  lazy(() => import("../pages/dashboard/accountant/ProcessCreateDoc"))
);
const ArchiveDocDetails = Loadable(
  lazy(() => import("../pages/dashboard/upload/ArchiveDocDetails"))
);
const WaitingDocDetails = Loadable(
  lazy(() => import("../pages/dashboard/upload/WaitingDocDetails"))
);
const GeneralDocDetails = Loadable(
  lazy(() => import("../pages/dashboard/docs/GeneralDocDetails"))
);
const UploadDocs = Loadable(
  lazy(() => import("../pages/dashboard/upload/UploadDocs"))
);
const CompanyHome = Loadable(
  lazy(() => import("../pages/dashboard/company/CompanyHome"))
);
const CompanyPackages = Loadable(
  lazy(() => import("../pages/dashboard/company/CompanyPackages"))
);
const CompanyUpgrade = Loadable(
  lazy(() => import("../pages/dashboard/company/CompanyUpgrade"))
);
const Checkout = Loadable(
  lazy(() => import("../pages/dashboard/checkout/Checkout"))
);
const CheckoutUpgrade = Loadable(
  lazy(() => import("../pages/dashboard/checkout/CheckoutUpgrade"))
);
const CheckoutImvoice = Loadable(
  lazy(() => import("../pages/dashboard/checkout/CheckoutImvoice"))
);
const FinanceReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/FinanceReport"))
);
const IncomeReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/IncomeReport"))
);
const ExpensesReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/ExpensesReport"))
);
const PurchaseReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/PurchaseReport"))
);
const CustomersReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/CustomersReport"))
);
const CustomersRaseeds = Loadable(
  lazy(() => import("../pages/dashboard/reports/CustomersRaseeds"))
);
const SuppliersReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/SuppliersReport"))
);
const SuppliersRaseeds = Loadable(
  lazy(() => import("../pages/dashboard/reports/SuppliersRaseeds"))
);
const ProfitabilityReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/ProfitabilityReport"))
);
const BudgetReport = Loadable(
  lazy(() => import("../pages/dashboard/reports/BudgetReport"))
);
const Users = Loadable(lazy(() => import("../pages/dashboard/user/Users")));
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/user/UserProfile"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/user/UserCreate"))
);

// INVOICE
const InvoiceDetails = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceDetails"))
);

const NotFound = Loadable(lazy(() => import("../pages/Page404")));

const Categories = Loadable(
  lazy(() => import("../pages/items/category/pages/Categories"))
);
const CategoryCreate = Loadable(
  lazy(() => import("../pages/items/category/pages/CategoryCreate"))
);
const CategoryView = Loadable(
  lazy(() => import("../pages/items/category/pages/CategoryView"))
);
const Products = Loadable(
  lazy(() => import("../pages/items/product/pages/Products"))
);
const ProductCreate = Loadable(
  lazy(() => import("../pages/items/product/pages/ProductCreate"))
);
const ProductView = Loadable(
  lazy(() => import("../pages/items/product/pages/ProductView"))
);

const StockProducts = Loadable(
  lazy(() => import("../pages/stock/items/pages/Products"))
);
const ProductsReport = Loadable(
  lazy(() => import("../pages/stock/items/pages/ProductsReport"))
);
const StockProductView = Loadable(
  lazy(() => import("../pages/stock/items/pages/ProductView"))
);

const Services = Loadable(
  lazy(() => import("../pages/items/service/pages/Services"))
);
const ServiceCreate = Loadable(
  lazy(() => import("../pages/items/service/pages/ServiceCreate"))
);
const ServiceView = Loadable(
  lazy(() => import("../pages/items/service/pages/ServiceView"))
);

// SALES
const Customers = Loadable(
  lazy(() => import("../pages/sales/customer/pages/Customers"))
);
const CustomerCreate = Loadable(
  lazy(() => import("../pages/sales/customer/pages/CustomerCreate"))
);
const CustomerView = Loadable(
  lazy(() => import("../pages/sales/customer/pages/CustomerView"))
);

const Invoices = Loadable(
  lazy(() => import("../pages/sales/invoice/pages/Invoices"))
);
const InvoiceCreate = Loadable(
  lazy(() => import("../pages/sales/invoice/pages/InvoiceCreate"))
);
const InvoiceView = Loadable(
  lazy(() => import("../pages/sales/invoice/pages/InvoiceView"))
);

// PURCHASE
const Suppliers = Loadable(
  lazy(() => import("../pages/purchase/supplier/pages/Suppliers"))
);
const SupplierCreate = Loadable(
  lazy(() => import("../pages/purchase/supplier/pages/SupplierCreate"))
);
const SupplierView = Loadable(
  lazy(() => import("../pages/purchase/supplier/pages/SupplierView"))
);

const PurchaseInvoices = Loadable(
  lazy(() => import("../pages/purchase/purchaseinvoice/pages/PurchaseInvoices"))
);
const PurchaseInvoiceCreate = Loadable(
  lazy(
    () =>
      import("../pages/purchase/purchaseinvoice/pages/PurchaseInvoiceCreate")
  )
);
const PurchaseInvoiceView = Loadable(
  lazy(
    () => import("../pages/purchase/purchaseinvoice/pages/PurchaseInvoiceView")
  )
);

// STOCK
const DeliveryIns = Loadable(
  lazy(() => import("../pages/stock/deliveryin/pages/DeliveryIns"))
);
const DeliveryInCreate = Loadable(
  lazy(() => import("../pages/stock/deliveryin/pages/DeliveryInCreate"))
);
const DeliveryInView = Loadable(
  lazy(() => import("../pages/stock/deliveryin/pages/DeliveryInView"))
);

const DeliveryOuts = Loadable(
  lazy(() => import("../pages/stock/deliveryout/pages/DeliveryOuts"))
);
const DeliveryOutCreate = Loadable(
  lazy(() => import("../pages/stock/deliveryout/pages/DeliveryOutCreate"))
);
const DeliveryOutView = Loadable(
  lazy(() => import("../pages/stock/deliveryout/pages/DeliveryOutView"))
);

const Stocks = Loadable(
  lazy(() => import("../pages/stock/stock/pages/Stocks"))
);
const StockCreate = Loadable(
  lazy(() => import("../pages/stock/stock/pages/StockCreate"))
);
const StockView = Loadable(
  lazy(() => import("../pages/stock/stock/pages/StockView"))
);

// POS
const POSReport = Loadable(
  lazy(() => import("../pages/sales/pos/pages/POSReport"))
);
const DailySales = Loadable(
  lazy(() => import("../pages/sales/pos/pages/DailySales"))
);

const Bills = Loadable(lazy(() => import("../pages/sales/bill/pages/Bills")));
const BillCreate = Loadable(
  lazy(() => import("../pages/sales/bill/pages/BillCreate"))
);
const BillView = Loadable(
  lazy(() => import("../pages/sales/bill/pages/BillView"))
);
const Pos = Loadable(lazy(() => import("../pages/pos/Pos")));
