import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useMemo } from "react";

import {
  createPosPurchase,
  deletePosPurchase,
  getPosPurchase,
  getPosPurchases,
  updatePosPurchase,
} from "../graphql/purchase";

export default ({ start, end, _id, supplierId }: any) => {
  const [getfins, finData] = useLazyQuery(getPosPurchases, {
    variables: { start, end, supplierId },
    fetchPolicy: "cache-and-network",
  });

  const refetchQueries = useMemo(() => {
    const qrs = [];
    if (start && end) {
      qrs.push({
        query: getPosPurchases,
        variables: { start, end, supplierId },
      });
    }
    return qrs;
  }, [start, end, supplierId]);

  const [addPosPurchase] = useMutation(createPosPurchase, {
    refetchQueries,
  });
  const [editPosPurchase] = useMutation(updatePosPurchase, {
    refetchQueries,
  });
  const [removePosPurchase] = useMutation(deletePosPurchase, {
    refetchQueries,
  });

  useEffect(() => {
    if (start && end) {
      getfins();
    }
  }, [getfins, start, end, supplierId]);

  const purchaseinvoices = finData?.data?.["getPosPurchases"]?.data || [];
  const refreshpurchaseinvoices = () => finData?.refetch();

  const purchaseinvoiceswithitems = useMemo(() => {
    return purchaseinvoices.map((purchaseinvoice: any) => {
      return {
        ...purchaseinvoice,
        invoiceitems: JSON.parse(purchaseinvoice.invoiceitems),
        attachments: JSON.parse(purchaseinvoice.attachments),
      };
    });
  }, [purchaseinvoices]);

  // ======================

  const [getf, fData] = useLazyQuery(getPosPurchase, {
    variables: { _id },
  });
  useEffect(() => {
    if (_id) {
      getf();
    }
  }, [_id]);
  const purchaseinvoice = fData?.data?.["getPosPurchase"]?.data || null;
  const purchaseinvoicewithitems = useMemo(() => {
    if (!purchaseinvoice) return null;
    return {
      ...purchaseinvoice,
      invoiceitems: JSON.parse(purchaseinvoice.invoiceitems),
      attachments: JSON.parse(purchaseinvoice.attachments),
    };
  }, [purchaseinvoice]);

  return {
    purchaseinvoices,
    purchaseinvoicewithitems,
    addPosPurchase,
    editPosPurchase,
    removePosPurchase,
    refreshpurchaseinvoices,
    purchaseinvoiceswithitems,
  };
};
