import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { List, Collapse, useTheme } from "@mui/material";
//
import { NavItemRoot, NavItemSub } from "./NavItem";
import { getActive } from "..";

// ----------------------------------------------------------------------

export function NavListRoot({
  list,
  isCollapse,
  isRTL,
  waitingcount,
  reviewcount,
  processingcount,
}: any) {
  const { pathname } = useLocation();
  const active = getActive(list.path, pathname);
  const [open, setOpen] = useState(active);
  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          isRTL={isRTL}
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
        />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item: any) => (
                <NavListSub isRTL={isRTL} key={item.title} list={item} />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <NavItemRoot
      isRTL={isRTL}
      item={list}
      active={active}
      isCollapse={isCollapse}
      waitingcount={waitingcount}
      reviewcount={reviewcount}
      processingcount={processingcount}
    />
  );
}

// ----------------------------------------------------------------------

function NavListSub({ list, isRTL }: any) {
  const { pathname } = useLocation();
  const active = getActive(list.path, pathname);
  const [open, setOpen] = useState(active);
  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          item={list}
          onOpen={() => setOpen(!open)}
          open={open}
          active={active}
          isRTL={isRTL}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item: any) => (
              <NavItemSub
                key={item.title}
                item={item}
                active={getActive(item.path, pathname)}
                isRTL={isRTL}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub isRTL={isRTL} item={list} active={active} />;
}
