import { useTranslation } from "react-i18next";
// '@mui
import { enUS, arEG } from "@mui/material/locale";
import useSettings from "./useSettings";
import useAuth from "./useAuth";

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: "عربي",
    value: "ar",
    systemValue: arEG,
    icon: "https://res.cloudinary.com/fivegstore/image/upload/v1661935815/qatar_kzmfj1.svg",
  },
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "https://res.cloudinary.com/fivegstore/image/upload/v1661935891/ic_flag_en_x3jelg.svg",
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { template }: any = useAuth();
  const { themeDirection, onChangeDirection }: any = useSettings();
  const langStorage = localStorage.getItem("i18nextLng");
  const currentLang =
    LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];
  const isRTL = currentLang?.value === "ar";
  const handleChangeLanguage = (newlang: any) => {
    i18n.changeLanguage(newlang);
    if (newlang === "ar" && themeDirection === "ltr") {
      onChangeDirection({ target: { value: "rtl" } });
    }
    if (newlang !== "ar" && themeDirection === "rtl") {
      onChangeDirection({ target: { value: "ltr" } });
    }
  };

  const tempwords = isRTL ? template?.words?.ar : template?.words?.en;

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    isRTL,
    allLang: LANGS,
    tempwords,
  };
}
