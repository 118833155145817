import { gql } from "@apollo/client";

export default gql`
  query getCategories {
    getCategories {
      ok
      error
      data {
        _id
        branch
        autoNo
        catType
        disabled
        additionId
        name
        nameAr
        desc
        avatar
        color
        order
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
