import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function LogoName({ disabledLink = false, sx }: any) {
  const logo = (
    <Box sx={{ width: 174, height: 40, ...sx, ml: 3, mt: -1 }}>
      <img
        alt={"Tadween"}
        src="https://res.cloudinary.com/tadween/image/upload/v1697228139/logo3_orcvr4.png"
      ></img>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
