import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useMemo } from "react";

import {
  createPosInvoice,
  deletePosInvoice,
  getPosInvoice,
  getPosInvoices,
  updatePosInvoice,
} from "../graphql/sales";

export default ({ start, end, _id, customerId }: any) => {
  const [getfins, finData] = useLazyQuery(getPosInvoices, {
    variables: { start, end, customerId },
    fetchPolicy: "cache-and-network",
  });

  const refetchQueries = useMemo(() => {
    const qrs = [];
    if (start && end) {
      qrs.push({
        query: getPosInvoices,
        variables: { start, end, customerId },
      });
    }

    return qrs;
  }, [start, end, customerId]);

  const [addPosInvoice] = useMutation(createPosInvoice, { refetchQueries });
  const [editPosInvoice] = useMutation(updatePosInvoice, { refetchQueries });
  const [removePosInvoice] = useMutation(deletePosInvoice, { refetchQueries });

  useEffect(() => {
    if (start && end) {
      getfins();
    }
  }, [getfins, start, end, customerId]);

  const invoices = finData?.data?.["getPosInvoices"]?.data || [];
  const refreshinvoices = () => finData?.refetch();

  const invoiceswithitems = useMemo(() => {
    return invoices.map((invoice: any) => {
      return {
        ...invoice,
        invoiceitems: JSON.parse(invoice.invoiceitems),
        attachments: JSON.parse(invoice.attachments),
      };
    });
  }, [invoices]);

  // ======================

  const [getf, fData] = useLazyQuery(getPosInvoice, {
    variables: { _id },
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (_id) {
      getf();
    }
  }, [_id]);
  const invoice = fData?.data?.["getPosInvoice"]?.data || null;
  const invoicewithitems = useMemo(() => {
    if (!invoice) return null;
    return {
      ...invoice,
      invoiceitems: JSON.parse(invoice.invoiceitems),
      attachments: JSON.parse(invoice.attachments),
    };
  }, [invoice]);

  return {
    invoices,
    invoicewithitems,
    addPosInvoice,
    editPosInvoice,
    removePosInvoice,
    refreshinvoices,
    invoiceswithitems,
  };
};
