import { Grid, Typography } from "@mui/material";
import { fNumber2 } from "../../utils/formatNumber";

function AmountView({ title, amount, color }: any) {
  return (
    <>
      <Grid item xs={12} md={6}>
        <Typography sx={{ color }} variant="subtitle1">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1" sx={{ textAlign: "right", color }}>
          {fNumber2(amount)}
        </Typography>
      </Grid>
    </>
  );
}

export default AmountView;
