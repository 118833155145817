import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Iconify from "../../../components/Iconify";
import { useGoogleLogin } from "@react-oauth/google";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";

function LoginGoogleForm({ isRTL }: any) {
  const [user, setUser]: any = useState(null);
  const { googin, isAuthenticated }: any = useAuth();

  const glogin = useGoogleLogin({
    onSuccess: (codeResponse: any) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const loginWithGoogle = async ({ user }: any) => {
    try {
      const res = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      );
      const data = res.data;
      if (data) {
        const { email, name, picture } = data;
        await googin({ email, name, avatar: picture });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      if (user) {
        loginWithGoogle({ user });
      }
    }
  }, [user]);

  return (
    <>
      <Button
        onClick={() => glogin()}
        variant="contained"
        color="secondary"
        style={{ marginTop: 20, height: 38 }}
        endIcon={
          <Iconify
            icon={"eva:google-fill"}
            color="#fff"
            width={24}
            height={24}
          />
        }
      >
        <Typography style={{ fontWeight: "bold" }}>
          {isRTL ? "تسجيل الدخول Google" : "Sign in with Google"}
        </Typography>
      </Button>
    </>
  );
}

export default LoginGoogleForm;
