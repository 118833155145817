import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import getAccounts from "../graphql/all/getAccounts";

export default () => {
  const [getAccs, accData]: any = useLazyQuery(getAccounts);

  useEffect(() => {
    getAccs();
  }, [getAccs]);

  const accounts = accData?.data?.["getAccounts"]?.data || [];
  const refreshAccount = () => accData?.refetch();

  return {
    accounts,
    refreshAccount,
  };
};
