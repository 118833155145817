import Router from "./routes";
import ThemeProvider from "./theme";
import RtlLayout from "./components/RtlLayout";
import { ChartStyle } from "./components/chart";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import ThemeColorPresets from "./components/ThemeColorPresets";
import ThemeLocalization from "./components/ThemeLocalization";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  split,
} from "@apollo/client";
import { AuthProvider } from "./contexts/JWTContext";
import { HelmetProvider } from "react-helmet-async";
import { PersistGate } from "redux-persist/integration/react";
import { Provider as ReduxProvider } from "react-redux";
import { SettingsProvider } from "./contexts/SettingsContext";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "./redux/store";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { graphqlURI, wsURI } from "./constants";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { enGB } from "date-fns/locale";
import { POSProvider } from "./contexts/POSContext";

// ----------------------------------------------------------------------

export default function App() {
  const [linkerror, setLinkerror] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const token = JSON.stringify({ accessToken });
  const httpLink = createHttpLink({ uri: graphqlURI });

  const wsLink = new GraphQLWsLink(createClient({ url: wsURI }));
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Tadween ${token}` : "",
      },
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    try {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message }) => {
          if (message === "auth token error") {
            console.log("auth token error");
            setLinkerror(true);
          }
        });
      if (networkError) console.log(`[Network error]: ${networkError}`);
    } catch (error) {
      console.log(error);
      setLinkerror(true);
    }
  });
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(splitLink),
    cache: new InMemoryCache(),
  });

  return (
    <GoogleOAuthProvider clientId="48838740441-tjjogqiqkfs14hkmrqpelmu3e66dm3m7.apps.googleusercontent.com">
      <ApolloProvider client={client}>
        <AuthProvider>
          <HelmetProvider>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <SettingsProvider>
                  <CollapseDrawerProvider>
                    <ThemeProvider>
                      <BrowserRouter>
                        <ThemeColorPresets>
                          <ThemeLocalization>
                            <RtlLayout>
                              <NotistackProvider>
                                <MotionLazyContainer>
                                  <ProgressBarStyle />
                                  <ChartStyle />
                                  <ScrollToTop />
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={enGB}
                                  >
                                    <POSProvider>
                                      <Router linkerror={linkerror} />
                                    </POSProvider>
                                  </LocalizationProvider>
                                </MotionLazyContainer>
                              </NotistackProvider>
                            </RtlLayout>
                          </ThemeLocalization>
                        </ThemeColorPresets>
                      </BrowserRouter>
                    </ThemeProvider>
                  </CollapseDrawerProvider>
                </SettingsProvider>
              </PersistGate>
            </ReduxProvider>
          </HelmetProvider>
        </AuthProvider>
      </ApolloProvider>
    </GoogleOAuthProvider>
  );
}
