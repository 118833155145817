import imageCompression from "browser-image-compression";
import { CLOUD_NAME, UPLOAD_PRESET, imageUploadOptions } from "../../constants";

const uploadMultiPhotoOnline = async (images: any) => {
  if (!images || images.length === 0) return null;
  const urls = [];
  try {
    for (const image of images) {
      const fsize = image.file.size;
      const rr = fsize > 1000000;
      if (rr) console.log("fsize", fsize);
      const compressedImage = rr
        ? await imageCompression(image.file, imageUploadOptions)
        : image.file;
      const d = new FormData();
      d.append("file", compressedImage);
      d.append("upload_preset", UPLOAD_PRESET);
      d.append("cloud_name", CLOUD_NAME);

      const res = await fetch(
        "https://api.cloudinary.com/v1_1/tadween/image/upload",
        {
          method: "post",
          body: d,
        }
      );
      const json = await res.json();
      urls.push(json.url);
    }

    return urls;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export const uploadMultiPhotoSpaces = async (docs: any) => {
  if (!docs || docs.length === 0) return null;
  const urls = [];
  try {
    for (const doc of docs) {
      const fsize = doc.file.size;
      const rr = fsize > 1000000;
      if (rr) console.log("fsize", fsize);
      const compressedImage = rr
        ? await imageCompression(doc.file, imageUploadOptions)
        : doc.file;
      const d = new FormData();
      d.append("file", compressedImage);
      d.append("upload_preset", UPLOAD_PRESET);
      d.append("cloud_name", CLOUD_NAME);

      const res = await fetch(
        "https://api.cloudinary.com/v1_1/tadween/image/upload",
        {
          method: "post",
          body: d,
        }
      );
      const json = await res.json();
      urls.push(json.url);
    }

    return urls;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

const uploadAttachmentsOnline = async (images: any) => {
  if (!images || images.length === 0) return null;
  const urls = [];
  try {
    for (const image of images) {
      const fsize = image.size;
      const rr = fsize > 1000000;
      if (rr) console.log("fsize", fsize);
      const compressedImage = rr
        ? await imageCompression(image, imageUploadOptions)
        : image;
      const d = new FormData();
      d.append("file", compressedImage);
      d.append("upload_preset", UPLOAD_PRESET);
      d.append("cloud_name", CLOUD_NAME);

      const res = await fetch(
        "https://api.cloudinary.com/v1_1/tadween/image/upload",
        {
          method: "post",
          body: d,
        }
      );
      const json = await res.json();
      urls.push(json.url);
    }

    return urls;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export const getUploadUrls = async (images: any) => {
  let attachments = [];
  if (images) {
    const photosurl = await uploadMultiPhotoOnline(images);
    if (photosurl && photosurl.length > 0) {
      const rphotos = photosurl.map((photo: any) =>
        photo.replace("http://", "https://")
      );
      attachments = rphotos;
    }
  }
  return attachments;
};
export const getAttachmentsUrls = async (images: any) => {
  let attachments = [];
  if (images) {
    const photosurl = await uploadAttachmentsOnline(images);
    if (photosurl && photosurl.length > 0) {
      const rphotos = photosurl.map((photo: any) =>
        photo.replace("http://", "https://")
      );
      attachments = rphotos;
    }
  }
  return attachments;
};

const uploadPhotoOnline = async (file: any) => {
  try {
    const fsize = file.size;
    const rr = fsize > 1000000;
    if (rr) console.log("fsize", fsize);
    const compressedImage = rr
      ? await imageCompression(file, imageUploadOptions)
      : file;
    const d = new FormData();
    d.append("file", compressedImage);
    d.append("upload_preset", UPLOAD_PRESET);
    d.append("cloud_name", CLOUD_NAME);

    const res = await fetch(
      "https://api.cloudinary.com/v1_1/tadween/image/upload",
      {
        method: "post",
        body: d,
      }
    );
    const json = await res.json();

    return json.url;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
export const getUploadUrl = async (file: any) => {
  if (file) {
    const photourl: any = await uploadPhotoOnline(file);
    if (photourl) {
      photourl.replace("http://", "https://");
      return photourl;
    } else {
      return null;
    }
  }
  return null;
};
