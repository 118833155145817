import { useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { setStoreItem } from "../../redux/helpers";

const AUTO_SAVE_DELAY = 1000;
const AUTO_SAVE_PEFIX = "auto_save_";

interface AutosaveProps {
  storeKey: string;
  data: any;
}
export default function Autosave({ storeKey, data }: AutosaveProps) {
  const saveData = useCallback((newData: any) => {
    setStoreItem(`${AUTO_SAVE_PEFIX}${storeKey}`, newData);
  }, []);
  const debouncedSave = useCallback(
    debounce(async (newData: any) => {
      saveData(newData);
    }, AUTO_SAVE_DELAY),
    []
  );
  useEffect(() => {
    if (data) {
      debouncedSave(data);
    }
  }, [data, debouncedSave]);
  return null;
}
