import { gql } from "@apollo/client";
export default gql`
  query getDeliveryOut($_id: String) {
    getDeliveryOut(_id: $_id) {
      ok
      error
      count
      data {
        _id
        docNo

        time
        opType
        title
        desc

        costAmount
        total
        discount
        amount

        refNo
        refType

        invoiceitems
        attachments

        supplierId
        supplierName
        supplierNameAr

        customerId
        customerName
        customerNameAr

        stockId
        stockName
        stockNameAr

        userId

        createdAt
        updatedAt
      }
    }
  }
`;
