import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import { HEADER, NAVBAR } from "../../config";
import DashboardHeader from "./header";
import NavbarVertical from "./navbar/NavbarVertical";
import useResponsive from "../../hooks/useResponsive";
import useAuth from "../../hooks/useAuth";
import useLocales from "../../hooks/useLocales";
import POSHeader from "./header/POSHeader";
import { useCompany } from "../../hooks";

// ----------------------------------------------------------------------

const MainStyle: any = styled("main", {
  shouldForwardProp: (prop: any) => prop !== "collapseClick",
})(({ collapseClick, theme }: any) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  // paddingBottom: HEADER.MOBILE_HEIGHT + 40,
  [theme.breakpoints.up("lg")]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
    backgroundColor: theme.palette.background.neutral,
  },
}));

const POSStyle: any = styled("main", {
  shouldForwardProp: (prop: any) => prop !== "collapseClick",
})(({ collapseClick, theme }: any) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  // paddingBottom: HEADER.MOBILE_HEIGHT + 40,
  [theme.breakpoints.up("lg")]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
    backgroundColor: theme.palette.background.neutral,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  const isDesktop = useResponsive("up", "lg", null, null);
  const { pathname } = useLocation();
  const { company } = useCompany();
  const { isRTL } = useLocales();
  const { user, view }: any = useAuth();
  const isAccountant = user?.isAccountant && view === "isAccountant";

  const isMain = pathname === "/dashboard/main";
  const isAccMain = pathname === "/dashboard/accountant/main";
  const isPOSDashboard = /^\/dashboard\/pos/.test(pathname);

  if (isPOSDashboard && isDesktop) {
    return (
      <Paper
        style={{
          overflow: "auto",
          paddingTop: isDesktop ? undefined : 10,
          paddingBottom: 25,
          backgroundColor: "#f4f4f4",
        }}
        sx={{
          display: { lg: "flex" },
          minHeight: { lg: 1 },
          overflow: "auto",
        }}
      >
        <POSHeader isFullscreen={isFullscreen} isCollapse={isCollapse} />
        <POSStyle collapseClick={collapseClick}>
          <Outlet />
        </POSStyle>
      </Paper>
    );
  } else {
    return (
      <Paper
        style={{
          overflow: "auto",
          paddingTop: isDesktop ? undefined : 10,
          paddingBottom: 25,
          backgroundColor: "#f4f4f4",
        }}
        sx={{
          display: { lg: "flex" },
          minHeight: { lg: 1 },
          overflow: "auto",
        }}
      >
        <DashboardHeader
          isCollapse={isCollapse}
          isAccountant={isAccountant}
          onOpenSidebar={() => setOpen(true)}
          company={isRTL ? company?.nameAr : company?.name}
        />
        {/* {!isDesktop && !isMain && !isAccMain && (
          <PageHeader
            isCollapse={isCollapse}
            isAccountant={isAccountant}
            onOpenSidebar={() => setOpen(true)}
            company={isRTL ? company?.nameAr : company?.name}
          />
        )} */}
        <NavbarVertical
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
      </Paper>
    );
  }
}
