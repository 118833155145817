import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import {
  getCompanies,
  createCompany,
  updateCompany,
  getCompany,
} from "../graphql/all";
import trialToSubscribtion from "../graphql/all/trialToSubscribtion";
import createTadweenPayment from "../graphql/all/createTadweenPayment";
import deleteTadweenPayment from "../graphql/all/deleteTadweenPayment";
import { calculateDaysLeft } from "../utils/helpers";
import getMyDueTadweenInvoices from "../graphql/all/getMyDueTadweenInvoices";
import _ from "lodash";

export default () => {
  const [getbras, branchData] = useLazyQuery(getCompanies);
  const [getComp, compData]: any = useLazyQuery(getCompany);
  const [getDueInv, dueinvData]: any = useLazyQuery(getMyDueTadweenInvoices);

  const [addCompany] = useMutation(createCompany, {
    refetchQueries: [{ query: getCompanies }, { query: getCompany }],
  });
  const [addTadweenPayment] = useMutation(createTadweenPayment);
  const [removeTadweenPayment] = useMutation(deleteTadweenPayment);
  const [trialToSub] = useMutation(trialToSubscribtion, {
    refetchQueries: [{ query: getCompanies }, { query: getCompany }],
  });
  const [editCompany] = useMutation(updateCompany, {
    refetchQueries: [{ query: getCompanies }, { query: getCompany }],
  });

  useEffect(() => {
    getbras();
    getComp();
    getDueInv();
  }, [getbras, getComp, getDueInv]);

  const companies = branchData?.data?.["getCompanies"]?.data;
  const dueinvoices = dueinvData?.data?.["getMyDueTadweenInvoices"]?.data;
  const totalinvoices =
    dueinvoices?.length > 0 ? _.sumBy(dueinvoices, "amount") : 0;
  const invoiceIds =
    dueinvoices?.length > 0 ? dueinvoices.map((inv: any) => inv._id) : [];
  let company: any;
  const comp1 = compData?.data?.["getCompany"]?.data || null;
  if (comp1) {
    company = JSON.parse(comp1);
  }
  const isTrial = company?.packId === 10;
  const isActive = company?.status === 1;
  const isPending = company?.status === 2;
  const isDisabled = company?.status === 3;
  const trialend = company?.packId ? company?.trialend : null;
  const trialdays = trialend ? calculateDaysLeft(trialend) : null;
  const invtime = dueinvoices?.length > 0 ? dueinvoices?.[0] : null;
  const invoicedays = invtime ? calculateDaysLeft(invtime?.time) : null;
  const isFresh = !(companies?.length > 0);

  return {
    company,
    loading: branchData?.loading,
    companies,
    addCompany,
    addTadweenPayment,
    removeTadweenPayment,
    trialToSub,
    editCompany,
    isTrial,
    isActive,
    isPending,
    isDisabled,
    trialdays,
    invoicedays,
    isFresh,
    invoiceIds,
    dueinvoices,
    totalinvoices,
  };
};
