import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Iconify from "./Iconify";

const HeaderBackButton: React.FC<{
  isRTL: boolean;
  translate?: any;
  title?: string;
}> = ({ isRTL, title, translate }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box
      display={"flex"}
      flexGrow={1}
      justifyContent={"space-between"}
      flexDirection={isRTL ? "row" : "row-reverse"}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography>{title}</Typography>
      </Box>
      <Button sx={{ height: 36 }} onClick={handleBack}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: isRTL ? "row-reverse" : "row",
            width: "100%",
          }}
        >
          <Iconify icon={"eva:arrow-ios-back-fill"} width={24} height={24} />
          <Typography
            sx={{ mx: 0.7, fontSize: 18, fontWeight: "bold", mb: 0.5 }}
          >
            {isRTL ? "رجوع" : "Back"}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};

export default HeaderBackButton;
