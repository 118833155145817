export const operationTypes = {
  letter: 1,
  request: 2,
  joborder: 3,
  order: 4,
  crmitem: 5,
  // المبيعات والمشتريات - زبائن وموردين
  salesInvoice: 10, /// فاتورة المبيع
  salesQoutation: 11,
  salesDelivery: 12, // مع فاتورة المبيعات عند تسليم المنتجات للزبون
  salesReturn: 13,
  customerReceipt: 14, // from customer // cash debit <- accounts_receivable credit
  customerDiscount: 15, // to customer //  accounts_receivable debit <- cash credit  خصمم خارج الفاتورة

  salesInvoiceCard: 20,
  salesInvoiceCredit: 21,
  customerReceiptCheque: 22,

  posInvoice: 23,
  posInvoiceReturn: 24,
  posPurchase: 25,
  posPurchaseReturn: 26,
  stockInventory: 27,
  stockAdjustmentIn: 28,
  stockAdjustmentOut: 29,

  purchaseInvoice: 30, // فاتورة الشراء
  purchaseOrder: 31, // طلب شراء قبل فاتورة الشراء
  purchaseDelivery: 32, //  مع طلب الشراء عند استلام المنتجات من المورد
  purchaseReturn: 33,
  supplierPayemnt: 34, // to supplier // accounts_payable debit <- cash credit
  supplierDiscount: 35, // from supplier // cash debit <- accounts_payable credit خصمم خارج الفاتورة
  purchaseInvoiceCredit: 36,
  purchaseInvoiceStock: 37,
  purchaseInvoiceCreditStock: 38,
  supplierPayemntCheque: 39,

  cheque: 40,
  installment: 41,

  interDeliveryIn: 50, // حركة استلام بضاعة من احد مخازن الشركة
  interDeliveryOut: 51, // حركة تسليم بضاعة لاحد مخازن الشركة

  expenses: 60,
  expproducts: 61,
  exppettycash: 62,
  exppayable: 63,
  expensesCredit: 64,
  expensesSalary: 65,
  advancedRent: 66,
  productsCost: 67,

  // حركة مالية
  // transfare cash - bank - card - partners - branchs
  deposet: 70, // سحب من الصندوق وايداع بالبنك // bank debit <- cash or card credit
  ownerDraw: 71, // سحب من الصندوق للشريك  // partenr debit <- cash credit
  ownerDeposit: 72, // اضافة من الشريك في الصندوق  // cash debit <- partenr credit

  pettyCashPay: 73, // دفع عهدة
  pettyCashRec: 74, // قبض عهدة
  cashDraw: 75, // سحب
  cashDeposet: 76, // ايداع
  advanceReceipt: 77, // قبض سلفة من موظف
  advancePayemnt: 78, // دفع لموظف سلفة

  chequeDraw: 85,
  cashDeposetCard: 86,
  cashDeposetCheque: 87,

  event: 80,
  task: 81,
  project: 82,

  // special transfare operation
  kaid: 90,
};

export const docTypesExtend = [
  {
    id: 1,
    value: operationTypes.salesInvoice,
    name: "Sales Invoices Cash",
    nameAr: "فواتير بيع نقدي",
    debitmainacc: 1,
    debitacc: 1000, // var
    creditmainacc: 13,
    creditacc: 4000,
    vardebitacc: true,
  },
  {
    id: 2,
    value: operationTypes.salesInvoiceCard,
    name: "Sales Invoices Card",
    nameAr: "فواتير بيع بطاقة",
    debitmainacc: 1,
    debitacc: 1010, // var
    creditmainacc: 13,
    creditacc: 4000,
    vardebitacc: true,
  },
  {
    id: 3,
    value: operationTypes.salesInvoiceCredit,
    name: "Sales Invoices Credit",
    nameAr: "فواتير بيع آجلة",
    debitmainacc: 2,
    debitacc: 1100,
    creditmainacc: 13,
    creditacc: 4000,
  },
  {
    id: 6,
    value: operationTypes.expenses,
    name: "Expenses Cash",
    nameAr: "مصروفات نقدية",
    debitmainacc: 15,
    debitacc: 6000,
    creditmainacc: 1,
    creditacc: 1000, // var
    varcreditacc: true,
  },
  {
    id: 7,
    value: operationTypes.expensesCredit,
    name: "Expenses Credit",
    nameAr: "مصروفات آجلة",
    debitmainacc: 15,
    debitacc: 6000,
    creditmainacc: 7,
    creditacc: 2000,
  },
  {
    id: 10,
    value: operationTypes.purchaseInvoice,
    name: "Purchase Invoices Cash",
    nameAr: "فواتير شراء نقدا",
    debitmainacc: 14,
    debitacc: 5050,
    creditmainacc: 1,
    creditacc: 1000, // var
    varcreditacc: true,
  },
  {
    id: 11,
    value: operationTypes.purchaseInvoiceCredit,
    name: "Purchase Invoices Credit",
    nameAr: "فواتير شراء آجلة",
    debitmainacc: 14,
    debitacc: 5000,
    creditmainacc: 7,
    creditacc: 2000,
  },
  {
    id: 4,
    value: operationTypes.customerReceipt,
    name: "Receipts",
    nameAr: "سندات قبض",
    debitmainacc: 1,
    debitacc: 1000, // var
    creditmainacc: 2,
    creditacc: 1100,
    vardebitacc: true,
  },
  {
    id: 14,
    value: operationTypes.supplierPayemnt,
    name: "Payemnts",
    nameAr: "سندات دفع",
    debitmainacc: 7,
    debitacc: 2000,
    creditmainacc: 1,
    creditacc: 1000, // var
    varcreditacc: true,
  },
  {
    id: 18,
    value: operationTypes.cashDeposet,
    name: "Cash Bank Deposet",
    nameAr: "ايداع نقدي في البنك",
    debitmainacc: 1,
    debitacc: 1020, // var
    creditmainacc: 1,
    creditacc: 1000,
    vardebitacc: true,
  },
  {
    id: 22,
    value: operationTypes.cashDraw,
    name: "Cash Bank Draw",
    nameAr: "سحب من البنك",
    debitmainacc: 1,
    debitacc: 1000,
    creditmainacc: 1,
    creditacc: 1020, // var
    varcreditacc: true,
  },
  {
    id: 25,
    value: operationTypes.kaid,
    name: "Other",
    nameAr: "اخرى",
    debitmainacc: null,
    debitacc: null,
    creditmainacc: null,
    creditacc: null,
  },
];
export const docTypesFull = [
  {
    id: 1,
    value: operationTypes.salesInvoice,
    name: "Sales Invoices Cash",
    nameAr: "فواتير بيع نقدي",
    debitmainacc: 1,
    debitacc: 1000, // var
    creditmainacc: 13,
    creditacc: 4000,
    vardebitacc: true,
  },
  {
    id: 2,
    value: operationTypes.salesInvoiceCard,
    name: "Sales Invoices Card",
    nameAr: "فواتير بيع بطاقة",
    debitmainacc: 1,
    debitacc: 1010, // var
    creditmainacc: 13,
    creditacc: 4000,
    vardebitacc: true,
  },
  {
    id: 3,
    value: operationTypes.salesInvoiceCredit,
    name: "Sales Invoices Credit",
    nameAr: "فواتير بيع آجلة",
    debitmainacc: 2,
    debitacc: 1100,
    creditmainacc: 13,
    creditacc: 4000,
  },
  {
    id: 4,
    value: operationTypes.customerReceipt,
    name: "Receipts",
    nameAr: "سندات قبض",
    debitmainacc: 1,
    debitacc: 1000, // var
    creditmainacc: 2,
    creditacc: 1100,
    vardebitacc: true,
  },
  {
    id: 5,
    value: operationTypes.customerReceiptCheque,
    name: "Receipts Cheque",
    nameAr: "سندات قبض شيكات",
    debitmainacc: 4,
    debitacc: 1150,
    creditmainacc: 2,
    creditacc: 1100, // var
    varcreditacc: true,
  },
  {
    opType: 23,
    value: operationTypes.posInvoice,
    name: "POS Invoice",
    nameAr: "فاتورة POS",
    debitmainacc: 1,
    debitacc: 1000, // var
    creditmainacc: 13,
    creditacc: 4000,
    vardebitacc: true,
  },
  {
    opType: 24,
    value: operationTypes.posInvoiceReturn,
    name: "POS Invoice Return",
    nameAr: "مرتجع فاتورة POS",
    ref: "posInvoiceReturn",
    perfix: "POSR",
    gref: "sales",
  },
  {
    opType: 25,
    value: operationTypes.posPurchase,
    name: "POS Purchase",
    nameAr: "فاتورة شراء POS",
    debitmainacc: 14,
    debitacc: 5050,
    creditmainacc: 1,
    creditacc: 1000, // var
    varcreditacc: true,
  },
  {
    opType: 26,
    value: operationTypes.posPurchaseReturn,
    name: "POS Purchase Return",
    nameAr: "مرتجع فاتورة شراء POS",
    ref: "posPurchaseReturn",
    perfix: "POPR",
    gref: "purchase",
  },
  {
    opType: 27,
    value: operationTypes.stockInventory,
    name: "Initial Inventory",
    nameAr: "الجرد الأولي",
    ref: "stockInventory",
    perfix: "STIN",
    gref: "delivery",
  },
  {
    opType: 28,
    value: operationTypes.stockAdjustmentIn,
    name: "Stock Adjustment In",
    nameAr: "تعديل مخزن اضافة",
    ref: "stockAdjustmentIn",
    perfix: "STAI",
    gref: "delivery",
  },
  {
    opType: 29,
    value: operationTypes.stockAdjustmentOut,
    name: "Stock Adjustment Out",
    nameAr: "تعديل مخزن سحب",
    ref: "stockAdjustmentOut",
    perfix: "STAO",
    gref: "delivery",
  },
  {
    id: 6,
    value: operationTypes.expenses,
    name: "Expenses Cash",
    nameAr: "مصروفات نقدية",
    debitmainacc: 15,
    debitacc: 6000,
    creditmainacc: 1,
    creditacc: 1000, // var
    varcreditacc: true,
  },
  {
    id: 7,
    value: operationTypes.expensesCredit,
    name: "Expenses Credit",
    nameAr: "مصروفات آجلة",
    debitmainacc: 15,
    debitacc: 6000,
    creditmainacc: 7,
    creditacc: 2000,
  },
  {
    id: 8,
    value: operationTypes.expensesSalary,
    name: "Accrued Salary",
    nameAr: "رواتب مستحقة",
    debitmainacc: 15,
    debitacc: 6100,
    creditmainacc: 8,
    creditacc: 2100,
  },
  {
    id: 9,
    value: operationTypes.advancedRent,
    name: "Advanced Rent",
    nameAr: "ايجارات مقدمة",
    debitmainacc: 4,
    debitacc: 1370,
    creditmainacc: 15,
    creditacc: 6200,
  },
  {
    id: 10,
    value: operationTypes.purchaseInvoice,
    name: "Purchase Invoices Cash",
    nameAr: "فواتير شراء نقدا",
    debitmainacc: 14,
    debitacc: 5050,
    creditmainacc: 1,
    creditacc: 1000, // var
    varcreditacc: true,
  },
  {
    id: 11,
    value: operationTypes.purchaseInvoiceCredit,
    name: "Purchase Invoices Credit",
    nameAr: "فواتير شراء آجلة",
    debitmainacc: 14,
    debitacc: 5000,
    creditmainacc: 7,
    creditacc: 2000,
  },
  {
    id: 12,
    value: operationTypes.purchaseInvoiceStock,
    name: "Purchase Invoices Stock",
    nameAr: "فواتير شراء مخزنية نقدا",
    debitmainacc: 3,
    debitacc: 1200,
    creditmainacc: 1,
    creditacc: 1000, // var
    varcreditacc: true,
  },
  {
    id: 13,
    value: operationTypes.purchaseInvoiceCreditStock,
    name: "Purchase Invoices Credit Stock",
    nameAr: "فواتير شراء مخزنية آجلة",
    debitmainacc: 3,
    debitacc: 1200,
    creditmainacc: 7,
    creditacc: 2000,
  },
  {
    id: 14,
    value: operationTypes.supplierPayemnt,
    name: "Payemnts",
    nameAr: "سندات دفع",
    debitmainacc: 7,
    debitacc: 2000,
    creditmainacc: 1,
    creditacc: 1000, // var
    varcreditacc: true,
  },
  {
    id: 15,
    value: operationTypes.supplierPayemntCheque,
    name: "Payemnts Cheque",
    nameAr: "سندات دفع شيكات",
    debitmainacc: 7,
    debitacc: 2000,
    creditmainacc: 8,
    creditacc: 2150,
  },
  {
    id: 16,
    value: operationTypes.productsCost,
    name: "Products Cost",
    nameAr: "تكلفة المنتجات المباعة",
    debitmainacc: 14,
    debitacc: 5000,
    creditmainacc: 3,
    creditacc: 1200,
  },
  {
    id: 17,
    value: operationTypes.expproducts,
    name: "Expenses Products",
    nameAr: "استهلاك المنتجات",
    debitmainacc: 14,
    debitacc: 5060,
    creditmainacc: 3,
    creditacc: 1200,
  },
  {
    id: 18,
    value: operationTypes.cashDeposet,
    name: "Cash Bank Deposet",
    nameAr: "ايداع نقدي في البنك",
    debitmainacc: 1,
    debitacc: 1020, // var
    creditmainacc: 1,
    creditacc: 1000,
    vardebitacc: true,
  },
  {
    id: 19,
    value: operationTypes.cashDeposetCard,
    name: "Card Bank Deposet",
    nameAr: "ايداع بطاقة في البنك",
    debitmainacc: 1,
    debitacc: 1020, // var
    creditmainacc: 1,
    creditacc: 1010,
    vardebitacc: true,
  },
  {
    id: 20,
    value: operationTypes.cashDeposetCheque,
    name: "Cheque Bank Deposet",
    nameAr: "ايداع شيك في البنك",
    debitmainacc: 1,
    debitacc: 1020, // var
    creditmainacc: 4,
    creditacc: 1150,
    vardebitacc: true,
  },
  {
    id: 21,
    value: operationTypes.chequeDraw,
    name: "Cheque Bank Draw",
    nameAr: "سحب شيك من البنك",
    debitmainacc: 8,
    debitacc: 2150,
    creditmainacc: 1,
    creditacc: 1020, // var
    varcreditacc: true,
  },
  {
    id: 22,
    value: operationTypes.cashDraw,
    name: "Cash Bank Draw",
    nameAr: "سحب من البنك",
    debitmainacc: 1,
    debitacc: 1000,
    creditmainacc: 1,
    creditacc: 1020, // var
    varcreditacc: true,
  },
  {
    id: 23,
    value: operationTypes.ownerDeposit,
    name: "Partner Deposet",
    nameAr: "ايداع الشريك",
    debitmainacc: 1,
    debitacc: 1020, // var
    creditmainacc: 8,
    creditacc: 2800,
    vardebitacc: true,
  },
  {
    id: 24,
    value: operationTypes.ownerDraw,
    name: "Partner Draw",
    nameAr: "سحب الشريك",
    debitmainacc: 8,
    debitacc: 2800,
    creditmainacc: 1,
    creditacc: 1020, // var
    varcreditacc: true,
  },
  {
    id: 25,
    value: operationTypes.kaid,
    name: "Jornal Vaucher",
    nameAr: "قيود اليومية",
    debitmainacc: null,
    debitacc: null,
    creditmainacc: null,
    creditacc: null,
  },
];
export const docTypesStock = [
  {
    _id: operationTypes.posInvoice,
    opType: 23,
    value: operationTypes.posInvoice,
    name: "POS Invoice",
    nameAr: "فاتورة POS",
    ref: "posInvoice",
    perfix: "POSI",
    gref: "sales",
  },
  {
    _id: operationTypes.posInvoiceReturn,
    opType: 24,
    value: operationTypes.posInvoiceReturn,
    name: "POS Invoice Return",
    nameAr: "مرتجع فاتورة POS",
    ref: "posInvoiceReturn",
    perfix: "POSR",
    gref: "sales",
  },
  {
    _id: operationTypes.posPurchase,
    opType: 25,
    value: operationTypes.posPurchase,
    name: "POS Purchase",
    nameAr: "فاتورة شراء POS",
    ref: "posPurchase",
    perfix: "POPU",
    gref: "purchase",
  },
  {
    _id: operationTypes.posPurchaseReturn,
    opType: 26,
    value: operationTypes.posPurchaseReturn,
    name: "POS Purchase Return",
    nameAr: "مرتجع فاتورة شراء POS",
    ref: "posPurchaseReturn",
    perfix: "POPR",
    gref: "purchase",
  },
  {
    _id: operationTypes.stockInventory,
    opType: 27,
    value: operationTypes.stockInventory,
    name: "Initial Inventory",
    nameAr: "الجرد الأولي",
    ref: "stockInventory",
    perfix: "STIN",
    gref: "delivery",
  },
  {
    _id: operationTypes.stockAdjustmentIn,
    opType: 28,
    value: operationTypes.stockAdjustmentIn,
    name: "Stock Adjustment In",
    nameAr: "تعديل مخزن اضافة",
    ref: "stockAdjustmentIn",
    perfix: "STAI",
    gref: "delivery",
  },
  {
    _id: operationTypes.stockAdjustmentOut,
    opType: 29,
    value: operationTypes.stockAdjustmentOut,
    name: "Stock Adjustment Out",
    nameAr: "تعديل مخزن سحب",
    ref: "stockAdjustmentOut",
    perfix: "STAO",
    gref: "delivery",
  },
  {
    _id: operationTypes.expproducts,
    id: 61,
    value: operationTypes.expproducts,
    name: "Products Expenses",
    nameAr: "استهلاك منتجات",
    ref: "expproducts",
    perfix: "EXPR",
    gref: "expenses",
  },
];

export const opTypesSale = [
  operationTypes.posInvoice,
  operationTypes.posPurchaseReturn,
  operationTypes.stockAdjustmentOut,
  operationTypes.expproducts,
];

export const opTypesPurchase = [
  operationTypes.posPurchase,
  operationTypes.posInvoiceReturn,
  operationTypes.stockInventory,
  operationTypes.stockAdjustmentIn,
];

export const opTypesSalePurchase = [
  operationTypes.posInvoice,
  operationTypes.posPurchaseReturn,
  operationTypes.stockAdjustmentOut,
  operationTypes.expproducts,
  operationTypes.posPurchase,
  operationTypes.posInvoiceReturn,
  operationTypes.stockInventory,
  operationTypes.stockAdjustmentIn,
];

export const docStatus = [
  { id: 1, name: "New Uploads", nameAr: "مضاف حديثا", color: "#2DCCFF" },
  // { id: 4, name: "Scheduled", nameAr: "غير مؤكد", color: "#808080" },
  { id: 6, name: "In Progress", nameAr: "قيد الانجاز", color: "#FED766" },
  { id: 7, name: "Require Action", nameAr: "مطلوب المراجعة", color: "#ff6666" },
  { id: 8, name: "Action Done", nameAr: "تمت المراجعة", color: "#808080" },
  { id: 9, name: "Canceled", nameAr: "مَلغى", color: "#ff0033aa" },
  { id: 10, name: "Archive", nameAr: "أرشيف", color: "#61E294" },
];

export const getPackages = (isRTL: any) => [
  // {
  //   id: 10,
  //   name: isRTL ? "الباقة التجريبية" : "TRIAL",
  //   price: 0,
  //   items: [
  //     { id: 1, name: isRTL ? "مستخدم واحد" : "One User" },
  //     {
  //       id: 2,
  //       name: isRTL ? "محاسب لتسجيل البيانات" : "Accountant for Data Entry",
  //     },
  //     {
  //       id: 3,
  //       name: isRTL
  //         ? "برنامج لمراقبة الوضع المالي"
  //         : "Dashboard - Company Statistics",
  //     },
  //     { id: 4, name: isRTL ? "تقارير شهرية" : "Monthly Reports" },
  //     { id: 5, name: isRTL ? "أرشفة المستندات" : "Archive Documents" },
  //     { id: 6, name: isRTL ? "دعم فني 24/7" : "24/7 Support" },
  //   ],
  // },
  {
    id: 1,
    name: isRTL ? "الباقة الأساسية" : "BASIC",
    price: 299,
    items: [
      { id: 1, name: isRTL ? "1 مستخدم" : "1 User" },
      {
        id: 2,
        name: isRTL ? "محاسب لتسجيل البيانات" : "Accountant for Data Entry",
      },
      {
        id: 3,
        name: isRTL
          ? "برنامج لمراقبة الوضع المالي"
          : "Dashboard - Company Statistics",
      },
      {
        id: 4,
        name: isRTL ? "نظام نقاط البيع" : "Cloud POS System",
        new: true,
      },
      {
        id: 5,
        name: isRTL ? "ادارة المخزون والمشتريات" : "Inventory & Purchase",
        new: true,
      },
      {
        id: 6,
        name: isRTL ? "المبيعات والفوترة" : "Sales & Invoicing",
        new: true,
      },
      { id: 7, name: isRTL ? "تقارير شهرية" : "Monthly Reports" },
      { id: 8, name: isRTL ? "أرشفة المستندات" : "Archive Documents" },
      { id: 9, name: isRTL ? "دعم فني 24/7" : "24/7 Support" },
    ],
  },
  {
    id: 2,
    name: isRTL ? "الباقة المتميزة" : "PREMIUM",
    price: 499,
    items: [
      { id: 1, name: isRTL ? "3 مستخدمين" : "3 Users", extra: true },
      {
        id: 2,
        name: isRTL ? "محاسب لتسجيل البيانات" : "Accountant for Data Entry",
      },
      {
        id: 3,
        name: isRTL
          ? "برنامج لمراقبة الوضع المالي"
          : "Dashboard - Company Statistics",
      },
      {
        id: 4,
        name: isRTL
          ? "مراكز تكلفة (عقود-مركبات)"
          : "Cost Centers (Contracts, Vehicles)",
        extra: true,
      },
      {
        id: 5,
        name: isRTL
          ? "كشوف العملاء والموردين"
          : "Customers/Suppliers Statments",
        extra: true,
      },
      {
        id: 6,
        name: isRTL ? "نظام نقاط البيع" : "Cloud POS System",
        new: true,
      },
      {
        id: 7,
        name: isRTL ? "ادارة المخزون والمشتريات" : "Inventory & Purchase",
        new: true,
      },
      {
        id: 8,
        name: isRTL ? "المبيعات والفوترة" : "Sales & Invoicing",
        new: true,
      },
      { id: 9, name: isRTL ? "تقارير شهرية" : "Monthly Reports" },
      { id: 10, name: isRTL ? "أرشفة المستندات" : "Archive Documents" },
      // { id: 11, name: isRTL ? "تحليل شهري" : "Monthly Analysis" },
      // { id: 12, name: isRTL ? "تقارير سنوية" : "Yearly Reports" },
      { id: 11, name: isRTL ? "دعم فني 24/7" : "24/7 Support" },
    ],
  },
  {
    id: 3,
    name: isRTL ? "الباقة الاحترافية" : "PRO",
    price: 599,
    items: [
      { id: 1, name: isRTL ? "3 مستخدمين" : "5 Users", extra: true },
      {
        id: 2,
        name: isRTL ? "محاسب لتسجيل البيانات" : "Accountant for Data Entry",
      },
      {
        id: 3,
        name: isRTL
          ? "برنامج لمراقبة الوضع المالي"
          : "Dashboard - Company Statistics",
      },
      {
        id: 4,
        name: isRTL
          ? "مراكز تكلفة (عقود-مركبات)"
          : "Cost Centers (Contracts, Vehicles)",
        extra: true,
      },
      {
        id: 5,
        name: isRTL
          ? "كشوف العملاء والموردين"
          : "Customers/Suppliers Statments",
        extra: true,
      },
      {
        id: 6,
        name: isRTL ? "نظام نقاط البيع" : "Cloud POS System",
        new: true,
      },
      {
        id: 7,
        name: isRTL ? "ادارة المخزون والمشتريات" : "Inventory & Purchase",
        new: true,
      },
      {
        id: 8,
        name: isRTL ? "المبيعات والفوترة" : "Sales & Invoicing",
        new: true,
      },
      { id: 9, name: isRTL ? "تقارير شهرية" : "Monthly Reports" },
      { id: 10, name: isRTL ? "أرشفة المستندات" : "Archive Documents" },
      { id: 11, name: isRTL ? "تحليل شهري" : "Monthly Analysis", extra: true },
      { id: 12, name: isRTL ? "تقارير سنوية" : "Yearly Reports", extra: true },
      // {
      //   id: 13,
      //   name: isRTL ? "إعداد التدقيق الضريبي" : "Tax Audit Preparation",
      // },
      { id: 13, name: isRTL ? "دعم فني 24/7" : "24/7 Support" },
    ],
  },
];

export const getPack = ({ id, isRTL }: any) => {
  const packs = getPackages(isRTL);
  return packs?.filter((p: any) => p.id === id)?.[0];
};

export const itemTypes = {
  service: 1,
  product: 2,
};
export const catTypesList = [
  { _id: 1, id: 1, value: 1, ref: "service", name: "Service", nameAr: "خدمة" },
  { _id: 2, id: 2, value: 2, ref: "product", name: "Product", nameAr: "منتج" },
  // { _id: 3, id: 3, value: 3, ref: "expense", name: "Expense", nameAr: "مصروف" },
];

export const deliveryInTypes = [
  {
    _id: operationTypes.stockInventory,
    id: operationTypes.stockInventory,
    value: operationTypes.stockInventory,
    opType: operationTypes.stockInventory,
    name: "Initial Inventory",
    nameAr: "الجرد الأولي",
  },
  {
    _id: operationTypes.stockAdjustmentIn,
    id: operationTypes.stockAdjustmentIn,
    value: operationTypes.stockAdjustmentIn,
    opType: operationTypes.stockAdjustmentIn,
    name: "Stock Adjustment Add",
    nameAr: "تعديل مخزون - إضافة",
  },
];

export const deliveryOutTypes = [
  {
    _id: operationTypes.expproducts,
    id: operationTypes.expproducts,
    value: operationTypes.expproducts,
    opType: operationTypes.expproducts,
    name: "Products Expenses",
    nameAr: "استهلاك منتجات",
  },
  {
    _id: operationTypes.stockAdjustmentOut,
    id: operationTypes.stockAdjustmentOut,
    value: operationTypes.stockAdjustmentOut,
    opType: operationTypes.stockAdjustmentOut,
    name: "Stock Adjustment Remove",
    nameAr: "تعديل مخزون - إزالة",
  },
];

export const statusIds = {
  forApproval_0: 0,
  approvelRequireAction_11: 11,
  newUploads_1: 1,
  inProgress_6: 6,
  requireAction_7: 7,
  actionDone_8: 8,
  canceled_9: 9,
  archive_10: 10,
};
