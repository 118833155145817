import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  deleteSpacesFile,
  getPresignedURL,
  listSpacesFiles,
} from "../graphql/spaces";
import { nanoid } from "../utils/helpers";
import useCompany from "./useCompany";
import imageCompression from "browser-image-compression";
import { imageUploadOptions, thumbUploadOptions } from "../constants";
const mainfolder = "tadween";

const useFileUpload = () => {
  const { company } = useCompany();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const branch = company?.basename;

  const [getURL] = useMutation(getPresignedURL);
  const [deletefromspace] = useMutation(deleteSpacesFile);
  const [listfiles] = useMutation(listSpacesFiles);

  const getSignedUrl = async (file: File, path: string) => {
    const uniqueId = nanoid();
    const variables = {
      filename: `${path}${uniqueId}_${file.name}`,
      filetype: file.type,
    };

    const res = await getURL({ variables });
    return res.data?.getPresignedURL?.data;
  };

  const uploadFiles = async (items: any) => {
    setIsSubmitting(true);
    const urls: string[] = [];
    try {
      for (const item of items) {
        const fsize = item?.file?.size;
        const isImage = item?.file?.type.startsWith("image/");
        const rr = fsize > 1000000;
        const rfile =
          rr && isImage
            ? await imageCompression(item.file, imageUploadOptions)
            : item.file;
        const path = isImage
          ? `${mainfolder}/${branch}/images/`
          : `${mainfolder}/${branch}/pdf/`;

        const signedUrl = await getSignedUrl(rfile, path);

        const res = await fetch(signedUrl, {
          method: "PUT",
          body: rfile,
          headers: {
            "Content-Type": rfile.type,
            "x-amz-acl": "public-read",
          },
        });
        if (res.ok) {
          const furl = signedUrl
            .split("?")?.[0]
            ?.replace(
              "https://jadwal.ams3.digitaloceanspaces.com/",
              "https://media.tadween.qa/"
            );
          urls.push(furl);
        } else {
          throw new Error(`Upload failed for file: ${rfile.name}`);
        }
        // Create and upload the thumbnail if the file is an image
        if (isImage) {
          const thumbFile = await imageCompression(
            item.file,
            thumbUploadOptions
          );
          const thumbSignedUrl = await getSignedUrl(
            thumbFile,
            `${mainfolder}/${branch}/thumb/`
          );
          await fetch(thumbSignedUrl, {
            method: "PUT",
            body: thumbFile,
            headers: {
              "Content-Type": thumbFile.type,
              "x-amz-acl": "public-read",
            },
          });
        }
      }
      setUploadedUrls(urls);
      setIsSubmitting(false);
      return urls;
    } catch (err) {
      setIsSubmitting(false);
      throw err;
    }
  };

  const deleteFile = async (filetodelete: string) => {
    const fileType = filetodelete.endsWith(".pdf") ? "pdf" : "image";
    const path =
      fileType === "image"
        ? `${mainfolder}/${branch}/images/`
        : `${mainfolder}/${branch}/pdf/`;

    const variables = { path: `${path}/${filetodelete}` };
    const res = await deletefromspace({ variables });
    console.log("res", res);
  };

  const listFiles = async () => {
    const path = `${mainfolder}/${branch}/`;
    const variables = { perfix: path };
    const res = await listfiles({ variables });
    console.log("res", res);
  };

  return {
    isSubmitting,
    uploadedUrls,
    uploadFiles,
    deleteFile,
    listFiles,
  };
};

export default useFileUpload;
