import { gql } from "@apollo/client";

export default gql`
  mutation changeUserBranch($branch: String) {
    changeUserBranch(branch: $branch) {
      ok
      message
      data {
        _id
        branch
        type
        username
        name
        email
        phone
        avatar
        address
        color
        lang
        tel
        fax
        mob

        billactive
        maxbranches

        publicKey

        roles
        newroles
        title
        publicKey

        isSuperAdmin
        isBranchsAdmin
        isBranchAdmin
        isAccountant
        isDepartAdmin
        isEmployee
        isFinance
        isOperate
        isPurchase
        isAdmin
        isEditor
        isWriter
        isViewer

        departmentId
        departmentName
        departmentNameAr
        departmentColor

        employeeId
        employeeName
        employeeNameAr
        employeePhone
        employeeColor

        stockId
        stockName
        stockNameAr

        verified
        payverified
        block
        themeId

        userId

        createdAt
        updatedAt
      }
      accessToken
      refreshToken
      hasCompany
      template
      tempId
      error
    }
  }
`;
