import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

import { getItemslist } from "../graphql/stock";

export default ({
  start,
  end,
  opType,
  itemType,
  categoryId,
  customerId,
  supplierId,
  contractId,
}: any) => {
  const [getemparts, empData]: any = useLazyQuery(getItemslist, {
    variables: {
      start,
      end,
      opType,
      itemType,
      categoryId,
      customerId,
      supplierId,
      contractId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    getemparts();
  }, [getemparts]);

  const items = useMemo(() => {
    const data = empData?.data?.["getItemslist"]?.data;
    return data;
  }, [
    empData,
    start,
    end,
    opType,
    itemType,
    categoryId,
    customerId,
    supplierId,
    contractId,
  ]);

  const refreshitem = () => empData?.refetch();

  return {
    itemlist: items || [],
    refreshitem,
  };
};
