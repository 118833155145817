// hooks
import useAuth from "../hooks/useAuth";
// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar from "./Avatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user }: any = useAuth();
  if (!user) return <div></div>;
  const { name, avatar, username } = user;
  const displayName = name ? name : username;
  return (
    <Avatar
      src={avatar}
      alt={displayName}
      color={avatar ? "default" : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </Avatar>
  );
}
