import React from "react";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import Iconify from "../../components/Iconify";

const LocationButton: React.FC<{
  location: string;
  isRTL: boolean;
  left?: boolean;
  width?: number;
}> = ({ location, isRTL, left = false, width }) => {
  const openlocation = () => {
    window.open(location, "_blank");
  };

  if (left) {
    return (
      <Button
        onClick={openlocation}
        sx={{ height: 40, width }}
        disabled={!location}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Iconify icon={"eva:map-fill"} width={24} height={24} />
          <Typography sx={{ mx: 1, fontWeight: "bold" }}>
            {isRTL ? "الموقع" : "Location"}
          </Typography>
        </Box>
      </Button>
    );
  }

  return (
    <Button
      onClick={openlocation}
      startIcon={<Iconify icon={"eva:map-fill"} width={24} height={24} />}
      sx={{ height: 40, width }}
      fullWidth
      disabled={!location}
    >
      {isRTL ? "الموقع" : "Location"}
    </Button>
  );
};

export default LocationButton;
