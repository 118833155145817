import { gql } from "@apollo/client";

export default gql`
  mutation updateStock(
    $_id: String
    $name: String
    $nameAr: String
    $desc: String
    $disabled: Boolean
    $additionId: String
    $note: String
  ) {
    updateStock(
      _id: $_id
      name: $name
      nameAr: $nameAr
      desc: $desc
      disabled: $disabled
      additionId: $additionId
      note: $note
    ) {
      ok
      message
      data
      error
    }
  }
`;
