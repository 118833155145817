import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import useOffSetTop from "../../../hooks/useOffSetTop";
import cssStyles from "../../../utils/cssStyles";
import { HEADER } from "../../../config";
import Iconify from "../../../components/Iconify";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useNavigate } from "react-router-dom";
import useLocales from "../../../hooks/useLocales";
import { exitfullscreen, fullscreen } from "../../../utils/helpers";
import { useAuth } from "../../../hooks";

const RootStyle: any = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isOffset, verticalLayout, theme }: any) => ({
  ...cssStyles(theme).bgBlur({}),
  boxShadow: "8px",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),

  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `100%`,
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

export default function POSHeader({
  isCollapse = false,
  verticalLayout = false,
  isFullscreen,
}: any) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const navigate = useNavigate();
  const { isRTL } = useLocales();
  const { user, hasCompany, notify, updatenotify, company }: any = useAuth();

  const onPOSClose = () => {
    navigate(-1);
    exitfullscreen();
  };

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
      hasCompany={hasCompany}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          backgroundColor: "background.paper",
          flexDirection: "row",
        }}
      >
        <Box>
          <Tooltip title="Back Home">
            <IconButton sx={{ mx: 1, fontSize: 32 }} onClick={onPOSClose}>
              <Iconify icon={"mdi:close"} />
            </IconButton>
          </Tooltip>
          {isFullscreen && (
            <Tooltip title="Exit Full Screen">
              <IconButton sx={{ mx: 1, fontSize: 32 }} onClick={exitfullscreen}>
                <Iconify icon={"mdi:fullscreen-exit"} />
              </IconButton>
            </Tooltip>
          )}
          {!isFullscreen && (
            <Tooltip title="Full Screen">
              <IconButton sx={{ mx: 1, fontSize: 32 }} onClick={fullscreen}>
                <Iconify icon={"mdi:fullscreen"} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontWeight: "bold", fontSize: 18, color: "primary.main" }}
          >
            {company?.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 22,
              color: "primary.main",
              mx: 1,
            }}
          >
            POS
          </Typography>
        </Box>

        <Stack
          direction={"row"}
          alignItems="center"
          spacing={{ xs: 3, sm: 3 }}
          sx={{ px: 2 }}
        >
          <NotificationsPopover
            user={user}
            updatenotify={updatenotify}
            notify={notify}
            isRTL={isRTL}
            isDesktop={true}
          />
          <AccountPopover isRTL={isRTL} />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
