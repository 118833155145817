import { gql } from "@apollo/client";
export default gql`
  query getStocks {
    getStocks {
      ok
      error
      data {
        _id
        branch
        autoNo
        disabled
        additionId
        main
        name
        nameAr
        desc
        note
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
