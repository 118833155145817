import { Box, Card, Typography, styled } from "@mui/material";
import Iconify from "../../components/Iconify";
import { useDropzone } from "react-dropzone";
import {
  getAttachmentsUrls,
  getUploadUrls,
} from "../../components/upload/helpers";
import { useState } from "react";
import ProgressCircular from "./ProgressCircular";
import GalleryUploadPhotos from "./GalleryUploadPhotos";

const DropZoneStyle = styled("div")(() => ({
  outline: "none",
  borderRadius: 20,
  "&:hover": { opacity: 0.8, cursor: "pointer" },
  display: "flex",
  flexGrow: 1,
}));

const UploadPhotos = ({
  value,
  onChange,
  translate,
  color = "#826AF915",
}: any) => {
  const images = value || [];
  const [loading, setloading] = useState(false);
  const onDrop = async (newImages: any) => {
    if (newImages) {
      if (images.length + newImages.length > 5)
        return alert("You can only upload 5 images");
      setloading(true);
      const photosurl = await getAttachmentsUrls(newImages);
      onChange([...images, ...photosurl]);
      setloading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      multiple: true,
      accept: { "image/*": [], "application/pdf": [] },
      maxSize: 3000000,
      maxFiles: 5,
    });
  const removeImage = (url: any) => {
    const newImages = images.filter((item: any) => item !== url);
    onChange(newImages);
  };

  return (
    <Card sx={{ backgroundColor: color }}>
      <Box sx={{ m: 1 }}>
        <Typography
          color="primary"
          sx={{ fontSize: 18, fontWeight: "bold", mx: 2, mb: 1 }}
        >
          {translate("attachments")}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          height: 160,
          borderRadius: 2,
          m: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            overflowY: "auto",
          }}
        >
          {images && images?.length > 0 && (
            <GalleryUploadPhotos images={images} removeImage={removeImage} />
          )}
          <DropZoneStyle
            {...getRootProps()}
            sx={{
              ...(isDragActive && { opacity: 0.8 }),
              ...(isDragReject && {
                color: "error.main",
                borderColor: "error.light",
                bgcolor: "error.lighter",
              }),
            }}
          >
            <input {...getInputProps()} />
            {loading ? (
              <Card
                sx={{
                  display: "flex",
                  p: 1,
                  m: 2,
                  width: 120,
                  height: 120,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#F5F4F4",
                }}
              >
                <ProgressCircular></ProgressCircular>
              </Card>
            ) : (
              <Card
                sx={{
                  display: "flex",
                  // p: 1,
                  m: 2,
                  width: 120,
                  height: 120,
                  cursor: "pointer",
                  backgroundColor: "#F5F4F4",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon={"ic:round-add-a-photo"}
                  sx={{
                    color: "#ccc",
                    width: 50,
                    height: 50,
                  }}
                />
              </Card>
            )}
          </DropZoneStyle>
        </Box>
      </Box>
    </Card>
  );
};

export default UploadPhotos;
