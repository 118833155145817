import { Navigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// routes
import { PATH_DASHBOARD, PATH_PAGE } from "../routes/paths";
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

export default function GuestGuard({ children }: any) {
  const { isAuthenticated, isInitialized } = useAuth();
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
