import { useState } from "react";
import LightboxModal from "./LightboxModal";
import { Card } from "@mui/material";
import Iconify from "./Iconify";
// ----------------------------------------------------------------------

export default function GalleryUploadPhotos({
  images,
  size = 120,
  removeImage,
}: any) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const imagesLightbox: any = images?.map((img: any) => img);
  const handleOpenLightbox = (url: any) => {
    const selectedImage = imagesLightbox.findIndex(
      (index: any) => index === url
    );
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  return (
    <>
      {images?.map((img: any, i: any) => (
        <Card
          key={i}
          sx={{
            display: "flex",
            m: 2,
            backgroundColor: "#F5F4F4",
            position: "relative",
          }}
        >
          <img
            src={img}
            alt={`Image ${i}`}
            style={{
              width: size,
              height: size,
              objectFit: "cover",
              cursor: "pointer",
            }}
            onClick={() => handleOpenLightbox(images[i])}
          />
          {removeImage && renderCloseIcon({ item: img, removeImage })}
        </Card>
      ))}
      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}

const renderCloseIcon = ({ item, removeImage }: any) => (
  <Iconify
    icon={"ic:round-close"}
    sx={{
      position: "absolute",
      top: 5,
      right: 5,
      width: 20,
      height: 20,
      color: "tomato",
      zIndex: 10,
      cursor: "pointer",
    }}
    onClick={() => removeImage(item)}
  />
);
