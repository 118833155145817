import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useMemo } from "react";

import {
  createDeliveryIn,
  deleteDeliveryIn,
  getDeliveryIn,
  getDeliveryIns,
  updateDeliveryIn,
} from "../graphql/stock";

export default ({ start, end, _id }: any) => {
  const [getfins, finData] = useLazyQuery(getDeliveryIns, {
    variables: { start, end },
    fetchPolicy: "cache-and-network",
  });

  const refetchQueries = useMemo(() => {
    const qrs = [];
    if (start && end) {
      qrs.push({
        query: getDeliveryIns,
        variables: { start, end },
      });
    }

    return qrs;
  }, [start, end]);

  const [addDeliveryIn] = useMutation(createDeliveryIn, { refetchQueries });
  const [editDeliveryIn] = useMutation(updateDeliveryIn, { refetchQueries });
  const [removeDeliveryIn] = useMutation(deleteDeliveryIn, { refetchQueries });

  useEffect(() => {
    if (start && end) {
      getfins();
    }
  }, [getfins, start, end]);

  const deliveryins = finData?.data?.["getDeliveryIns"]?.data || [];
  const refreshdeliveryins = () => finData?.refetch();

  const deliveryinswithitems = useMemo(() => {
    return deliveryins.map((deliveryin: any) => {
      return {
        ...deliveryin,
        invoiceitems: deliveryin.invoiceitems
          ? JSON.parse(deliveryin.invoiceitems)
          : [],
        attachments: deliveryin.attachments
          ? JSON.parse(deliveryin.attachments)
          : [],
      };
    });
  }, [deliveryins]);

  // ======================

  const [getf, fData] = useLazyQuery(getDeliveryIn, {
    variables: { _id },
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (_id) {
      getf();
    }
  }, [_id]);
  const deliveryin = fData?.data?.["getDeliveryIn"]?.data || null;
  const deliveryinwithitems = useMemo(() => {
    if (!deliveryin) return null;
    return {
      ...deliveryin,
      invoiceitems: deliveryin.invoiceitems
        ? JSON.parse(deliveryin.invoiceitems)
        : [],
      attachments: deliveryin.attachments
        ? JSON.parse(deliveryin.attachments)
        : [],
    };
  }, [deliveryin]);

  return {
    deliveryins,
    deliveryinwithitems,
    addDeliveryIn,
    editDeliveryIn,
    removeDeliveryIn,
    refreshdeliveryins,
    deliveryinswithitems,
  };
};
