import { gql } from "@apollo/client";

export default gql`
  mutation createTadweenPayment(
    $customerId: String
    $branchId: String
    $orderId: String
    $invoiceIds: [String]
    $amount: Float
    $packId: Int
    $name: String
    $nameAr: String
    $tel1: String
    $mob: String
    $paytype: String
  ) {
    createTadweenPayment(
      customerId: $customerId
      branchId: $branchId
      orderId: $orderId
      invoiceIds: $invoiceIds
      amount: $amount
      packId: $packId
      name: $name
      nameAr: $nameAr
      tel1: $tel1
      mob: $mob
      paytype: $paytype
    ) {
      ok
      message
      data
      error
    }
  }
`;
