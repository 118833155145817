import { gql } from "@apollo/client";

export default gql`
  mutation updatePosPurchase(
    $_id: String
    $time: Date
    $title: String
    $desc: String
    $supplierId: String
    $supplierName: String
    $supplierNameAr: String
    $invoiceitems: String
    $total: Float
    $discount: Float
    $amount: Float
    $amountPaid: Float # cash
    $amountPaid2: Float # card/bank
    $attachments: String
    $stockId: String
    $stockName: String
    $stockNameAr: String
  ) {
    updatePosPurchase(
      _id: $_id
      time: $time
      title: $title
      desc: $desc
      supplierId: $supplierId
      supplierName: $supplierName
      supplierNameAr: $supplierNameAr
      invoiceitems: $invoiceitems
      total: $total
      discount: $discount
      amount: $amount
      amountPaid: $amountPaid
      amountPaid2: $amountPaid2
      attachments: $attachments
      stockId: $stockId
      stockName: $stockName
      stockNameAr: $stockNameAr
    ) {
      ok
      message
      data
      error
    }
  }
`;
