import { createContext, useContext, useReducer } from "react";
import { getStoreItem, setStoreItem } from "../redux/helpers";

const initstate = {
  start: null,
  end: null,
  view: "day",

  setStart: (start: Date) => {},
  setEnd: (end: Date) => {},
  setView: (view: string) => {},
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "setStart":
      const startState = { ...state, start: action.start };
      setStoreItem("purchaseState", startState);
      return startState;
    case "setEnd":
      const endState = { ...state, end: action.end };
      setStoreItem("purchaseState", endState);
      return endState;
    case "setView":
      const viewState = { ...state, view: action.view };
      setStoreItem("purchaseState", viewState);
      return viewState;

    default:
      throw new Error("Unexpected action");
  }
};

const Context = createContext(initstate);

export const PurchaseProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(
    reducer,
    getStoreItem("purchaseState") || initstate
  );
  const setStart = (start: any) => dispatch({ type: "setStart", start });
  const setEnd = (end: any) => dispatch({ type: "setEnd", end });
  const setView = (view: any) => dispatch({ type: "setView", view });

  return (
    <Context.Provider
      value={{
        ...state,
        setStart,
        setEnd,
        setView,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const usePurchaseContext = () => useContext(Context);
