import { useState } from "react";
import { Button, MenuItem, Stack, Typography } from "@mui/material";
import useLocales from "../../../hooks/useLocales";
import Image from "../../../components/Image";
import MenuPopover from "../../../components/MenuPopover";

// ----------------------------------------------------------------------

export default function LanguagePopover({ color = "#333" }) {
  const { allLang, currentLang, onChangeLang, isRTL } = useLocales();
  const [open, setOpen] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Button onClick={handleOpen}>
        <Typography sx={{ color }}>{isRTL ? "Lnaguage" : "اللغة"}</Typography>
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                handleClose();
              }}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
