import { gql } from "@apollo/client";

export default gql`
  mutation loginGoogle($email: String, $name: String, $avatar: String) {
    loginGoogle(email: $email, name: $name, avatar: $avatar) {
      ok
      message
      data {
        _id
        branch
        branchs
        type
        username
        name
        email
        phone
        avatar
        address
        color
        lang
        tel
        fax
        mob

        billactive
        maxbranches

        roles

        isBranchAdmin
        isDepartAdmin
        isAccountant
        isEmployee
        isFinance
        isOperate
        isPurchase
        isAdmin
        isEditor
        isWriter
        isViewer

        departmentId
        departmentName
        departmentNameAr
        departmentColor

        employeeId
        employeeName
        employeeNameAr
        employeePhone
        employeeColor

        stockId
        stockName
        stockNameAr

        verified
        payverified
        block
        themeId

        userId

        createdAt
        updatedAt
      }
      accessToken
      refreshToken
      hasCompany
      template
      tempId
      error
    }
  }
`;
