import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import getNotifications from "../graphql/all/getNotifications";
import updatePushToken from "../graphql/all/updatePushToken";
import updateNotifiReadMulti from "../graphql/all/updateNotifiReadMulti";
import updateNotifiRead from "../graphql/all/updateNotifiRead";

export default () => {
  const [loadMsgs, msgsData]: any = useLazyQuery(getNotifications, {
    fetchPolicy: "cache-and-network",
  });
  const [updatePush] = useMutation(updatePushToken);
  const [readall] = useMutation(updateNotifiReadMulti, {
    refetchQueries: [{ query: getNotifications }],
  });
  const [read] = useMutation(updateNotifiRead, {
    refetchQueries: [{ query: getNotifications }],
  });

  useEffect(() => {
    loadMsgs();
  }, [loadMsgs]);

  const notifications = msgsData?.data?.["getNotifications"]?.data || [];
  const shortlist = notifications.slice(0, 20);
  const newin = notifications?.filter((d: any) => !d?.read)?.length;

  const refreshnotification = () => msgsData?.refetch();
  return {
    newin,
    read,
    readall,
    updatePush,
    shortlist,
    notifications,
    refreshnotification,
  };
};
