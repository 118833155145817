import { IconButton, alpha } from "@mui/material";
import React from "react";
import { nameToColor } from "../../utils/helpers";

interface AvatarProps {
  src: string;
  alt: string;
  size?: number;
  color?: string;
  onViewRow?: any;
}

const AvatarImage: React.FC<AvatarProps> = ({
  src,
  alt,
  size = 45,
  color,
  onViewRow = () => null,
}) => {
  const fcolor = color ? color : nameToColor(alt);
  if (src) {
    return (
      <img
        src={src}
        alt={alt}
        style={{
          width: size,
          height: size,
          borderRadius: "50%",
        }}
      />
    );
  } else {
    return (
      <IconButton
        onClick={onViewRow}
        sx={{
          width: size,
          height: size,
          borderRadius: "50%",
          bgcolor: fcolor,
          "&:hover": {
            bgcolor: fcolor ? alpha(fcolor, 0.8) : "primary.light",
          },
        }}
      />
    );
  }
};

export default AvatarImage;
