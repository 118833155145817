import { useState } from "react";
import { Button, Typography, MenuItem, Stack, Divider } from "@mui/material";
import useLocales from "../../../hooks/useLocales";
import MenuPopover from "../../../components/MenuPopover";
import Iconify from "../../../components/Iconify";
import useCompanies from "../../../hooks/useCompanies";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

export default function CompanyPopover() {
  const [open, setOpen] = useState(null);
  const { user, changeBranch }: any = useAuth();
  const { companies, company } = useCompanies();
  const navigate = useNavigate();
  const { isRTL } = useLocales();
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeBranch = (branch: any) => {
    if (company?.basename === branch) {
      handleClose();
      return;
    }
    changeBranch(branch);
    handleClose();
    navigate(PATH_DASHBOARD.root);
  };

  if (!companies || companies?.length === 0)
    return (
      <Button
        onClick={() => null}
        sx={{
          ...(open ? { bgcolor: "action.selected" } : {}),
          px: 2,
        }}
      >
        <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
          {isRTL ? company?.nameAr : company?.name}
        </Typography>
      </Button>
    );

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          ...(open ? { bgcolor: "action.selected" } : {}),
          px: 2,
        }}
        endIcon={<Iconify icon={"eva:arrow-ios-downward-fill"} />}
      >
        <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
          {isRTL ? company?.nameAr : company?.name}
        </Typography>
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          minWidth: 250,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {companies.map((option: any) => (
            <MenuItem
              key={option?.basename}
              selected={option?.basename === company?.basename}
              onClick={() => {
                handleChangeBranch(option?.basename);
              }}
            >
              {isRTL ? option?.nameAr : option?.name}
            </MenuItem>
          ))}
          {user?.isBranchsAdmin && (
            <>
              <Divider></Divider>
              <MenuItem
                onClick={() => {
                  navigate(PATH_DASHBOARD.company.packages);
                  handleClose();
                }}
                style={{ color: "darkblue" }}
              >
                {isRTL ? "انشاء شركة جديدة" : "Create New Company"}
              </MenuItem>
            </>
          )}
        </Stack>
      </MenuPopover>
    </>
  );
}
