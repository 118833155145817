import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Badge, Box, Link, ListItemText, useTheme } from "@mui/material";
//
import Iconify from "../../Iconify";
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from "./style";
import { isExternalLink } from "..";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

export function NavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  onOpen,
  isRTL,
  waitingcount,
  reviewcount,
  processingcount,
}: any) {
  const { title, titleAr, path, icon, info, children } = item;
  const theme = useTheme();
  const renderContent = (
    <>
      {icon && (
        <>
          {path === PATH_DASHBOARD.upload.approval ? (
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={waitingcount}
              color="warning"
            >
              <ListItemIconStyle
                style={{ color: theme.palette.primary.contrastText }}
              >
                {icon}
              </ListItemIconStyle>
            </Badge>
          ) : path === PATH_DASHBOARD.upload.review ? (
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={reviewcount}
              color="error"
            >
              <ListItemIconStyle
                style={{ color: theme.palette.primary.contrastText }}
              >
                {icon}
              </ListItemIconStyle>
            </Badge>
          ) : path === PATH_DASHBOARD.upload.processing ? (
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={processingcount}
              color="secondary"
            >
              <ListItemIconStyle
                style={{ color: theme.palette.primary.contrastText }}
              >
                {icon}
              </ListItemIconStyle>
            </Badge>
          ) : path === PATH_DASHBOARD.accountant.processing ? (
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={processingcount}
              color="secondary"
            >
              <ListItemIconStyle
                style={{ color: theme.palette.primary.contrastText }}
              >
                {icon}
              </ListItemIconStyle>
            </Badge>
          ) : (
            <ListItemIconStyle
              style={{ color: theme.palette.primary.contrastText }}
            >
              {icon}
            </ListItemIconStyle>
          )}
        </>
      )}
      <ListItemTextStyle
        disableTypography
        primary={isRTL ? titleAr : title}
        isCollapse={isCollapse}
        style={{ color: theme.palette.primary.contrastText }}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} isRTL={isRTL} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

export function NavItemSub({
  item,
  open = false,
  active = false,
  onOpen,
  isRTL,
}: any) {
  const { title, titleAr, path, info, children } = item;
  const theme = useTheme();
  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText
        style={{ color: theme.palette.primary.contrastText }}
        disableTypography
        primary={isRTL ? titleAr : title}
      />
      {info && info}
      {children && <ArrowIcon open={open} isRTL={isRTL} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

export function DotIcon({ active }: any) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 5,
          height: 5,
          borderRadius: "50%",
          bgcolor: "text.disabled",
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: "scale(1.5)",
            bgcolor: "#eee",
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

export function ArrowIcon({ open, isRTL }: any) {
  return (
    <Iconify
      icon={
        open
          ? "eva:arrow-ios-downward-fill"
          : isRTL
          ? "eva:arrow-ios-back-fill"
          : "eva:arrow-ios-forward-fill"
      }
      sx={{ width: 20, height: 20, ml: 1, color: "#eee" }}
    />
  );
}
