import { gql } from "@apollo/client";

export default gql`
  mutation updateNotifiReadMulti($ids: [String]) {
    updateNotifiReadMulti(ids: $ids) {
      ok
      message
      data
      error
    }
  }
`;
