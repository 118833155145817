import { capitalCase } from "change-case";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Tooltip,
  Container,
  Typography,
  Link,
  Button,
} from "@mui/material";
// hooks
import useAuth from "../../hooks/useAuth";
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../../components/Page";
import Image from "../../components/Image";
// sections
import { LoginForm } from "../../sections/auth/login";
import useLocales from "../../hooks/useLocales";

import LoginGoogleForm from "../../sections/auth/login/LoginGoogleForm";
import { PATH_AUTH } from "../../routes/paths";
import LanguagePopover from "../../layouts/dashboard/header/LanguagePopover";
import { useEffect } from "react";
import { appversion } from "../../constants";
import useSearchQuery from "../../hooks/useSearchQuery";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "row-reverse",
    backgroundColor: "#f4f4f4",
  },
}));

const SectionStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "55%",
  display: "flex",
  // flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(20, 10, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(4),
  backgroundColor: "#fff",
}));

export default function Login() {
  const mdUp = useResponsive("up", "md", null, null);
  const { isRTL, onChangeLang, currentLang } = useLocales();
  const { method, updateversion, version }: any = useAuth();
  const navigate = useNavigate();
  let query = useSearchQuery();
  const lang = query.get("lang");

  useEffect(() => {
    if (version !== appversion) {
      updateversion(appversion, false);
      navigate("/", { replace: true });
    }
  }, [appversion]);
  useEffect(() => {
    if (lang) {
      if (lang === "ar") {
        onChangeLang("ar");
      }
      if (lang === "en") {
        onChangeLang("en");
      }
    } else {
      if (!currentLang) {
        onChangeLang("en");
      }
    }
  }, [lang]);

  return (
    <Page title={isRTL ? "تسجيل الدخول" : "Login"}>
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Box sx={{ overflow: "hidden" }}>
              <Image
                visibleByDefault
                disabledEffect
                alt={isRTL ? "تسجيل الدخول" : "Login"}
                src="https://res.cloudinary.com/jadwalio/image/upload/v1683366707/service1_lpvkle.png"
              />
            </Box>
          </SectionStyle>
        )}

        <Box
          style={{
            position: "absolute",
            left: 10,
            top: 10,
            width: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LanguagePopover></LanguagePopover>
        </Box>
        <Container maxWidth="sm">
          <ContentStyle>
            <Box
              sx={{
                marginBottom: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={
                      "https://res.cloudinary.com/tadween/image/upload/v1695666847/logo3_dpnm2w.jpg"
                    }
                    sx={{ width: 300, height: 120 }}
                  />
                </>
              </Tooltip>
            </Box>
            <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {isRTL ? "تسجيل الدخول" : "Sign in"}
                </Typography>
              </Box>
            </Stack>
            <LoginForm isRTL={isRTL} />
            <LoginGoogleForm isRTL={isRTL}></LoginGoogleForm>
            <Typography variant="body2" sx={{ my: 3 }}>
              {isRTL
                ? "ليس لديك حساب على منصة تدوين"
                : "Don’t have an account?"}{" "}
              {/* <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                {isRTL ? "انشاء حساب" : "Get started"}
              </Link> */}
              <Button
                variant="contained"
                color="warning"
                component={RouterLink}
                to={PATH_AUTH.register}
                fullWidth
                sx={{ mt: 1 }}
              >
                {isRTL ? "انشاء حساب" : "Create Account"}
              </Button>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
