import { useEffect, useState } from "react";
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListItemButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { fToNow } from "../../../utils/formatTime";
import { _notifications } from "../../../_mock";
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";
import useLocales from "../../../hooks/useLocales";

import { subscribePushToken } from "../../../utils/helpers";
import useNotifications from "../../../hooks/useNotifications";

const newVariable: any = window?.navigator;

export default function NotificationsPopover({
  user,
  updatenotify,
  notify,
  isRTL,
  notifisub,
  isDesktop,
}: any) {
  const [open, setOpen] = useState(null);

  const { translate } = useLocales();
  const {
    refreshnotification,
    notifications,
    newin,
    readall,
    read,
    updatePush,
  } = useNotifications();

  useEffect(() => {
    if (
      notifisub?.data?.notifiAdded?.userId &&
      notifisub?.data?.notifiAdded?.userId === user?._id
    ) {
      refreshnotification();
    }
  }, [notifisub]);

  const handleSubscribe = async (e: any) => {
    const { checked } = e.target;
    updatenotify(checked);
    try {
      const pushToken = await subscribePushToken(user, checked);
      const variables = { pushToken, notify: checked };
      await updatePush({ variables });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    const ids = notifications?.map((not: any) => not?._id);
    try {
      if (ids && ids?.length > 0) {
        await readall({ variables: { ids } });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (newVariable?.setAppBadge) {
      // Display the number of unread messages.
      newVariable.setAppBadge(newin);
    }
  }, [newin]);

  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={newin} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: isDesktop ? 360 : 300,
          p: 0,
          mt: 1.5,
          ml: 0.75,
          overFlow: "auto",
          boxShadow: 10,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {translate("notifications")}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {translate("Youhave")} {newin} {translate("unreadmessages")}
            </Typography>
          </Box>
          <Box>
            <Tooltip
              title={isRTL ? "تفعيل التنبيهات" : "Activate Notification"}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={notify}
                    onChange={handleSubscribe}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                labelPlacement="end"
                label=""
              />
            </Tooltip>
          </Box>
          {/* {newin === 0 && (
            <Tooltip title={isRTL ? "قراءة الكل" : "Mark all as read"}>
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: 500, overflow: "auto" }}>
          <List disablePadding>
            {notifications?.map((notification: any) => (
              <NotificationItem
                key={notification?._id}
                notification={notification}
                read={read}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth onClick={handleMarkAllAsRead}>
            {isRTL ? "قراءة الكل" : "Mark all as read"}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

function NotificationItem({ notification, read }: any) {
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.read && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={() => read({ variables: { _id: notification?._id } })}
    >
      <ListItemText
        primary={notification?.title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification?.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
