import { gql } from "@apollo/client";

export default gql`
  mutation deleteDeliveryOut($_id: String) {
    deleteDeliveryOut(_id: $_id) {
      ok
      message
      error
    }
  }
`;
