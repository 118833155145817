import { gql } from "@apollo/client";

export default gql`
  query getCustomers {
    getCustomers {
      ok
      error
      data {
        _id
        branch
        disabled
        additionId
        name
        nameAr
        phone
        email
        mobile
        address
        houseno
        avatar
        logo

        amount
        totalinvoiced
        totalDiscount
        totalPurchaseInvoiced
        totalPurchasePaid
        totalPurchaseDiscount
        toatlProdExpenses
        totalpaid
        toatlExpenses
        totalkaidsdebit
        totalKaidscredit

        userId
        createdAt
        updatedAt
      }
    }
  }
`;
