import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function Logo({ disabledLink = false, sx }: any) {
  const theme = useTheme();
  const logo = (
    <Box sx={{ width: 50, height: 50, ...sx }}>
      <img
        alt={"Tadween"}
        src="https://res.cloudinary.com/jadwalio/image/upload/v1683314474/logo-simple_gueh0z.png"
      ></img>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
