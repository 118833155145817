import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const RootStyle = styled("div")(() => ({
  height: "100%",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function EmptyContent({
  title,
  description,
  img,
  ...other
}: any) {
  return (
    <RootStyle {...other}>
      <Typography variant="h4" gutterBottom style={{ color: "#ddd" }}>
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      )}
    </RootStyle>
  );
}
